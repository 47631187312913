import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteEvent,
  removeFavoriteEvent,
  selectFavEvents,
} from "../../store/favSlice";
import { sportsEventHeaderItemMinWidths, sportsEventHeaderItems } from "./SportsOddsHeader";
import { dateToText } from "src/app/utils";
import { addTicket, removeTicket, selectTickets } from "../../store/ticketSlice";
import { selectBadgetPosition, selectBetType } from "src/app/main/store/uiSlice";
import { betTypeTitles } from "../outlets/EventDetail/OddsContainer";
import { useScreenSm } from "src/app/hooks/useScreens";
import { selectUserOddsFormat } from "../../store/uiSlice";
import { convertOdds } from "src/app/utils";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { CustomImage } from "app/shared-components/CustomImage";
import { useGameTimer } from "src/app/hooks/useGameTimer";
import { useIsLive } from "src/app/hooks/useIsLive";
import i18next from "i18next";
import { selectCurrentLanguage } from "app/store/i18nSlice";

const shouldAddDivider = (position) => {
  return ["2", "O", "2X", "H2"].includes(position);
};

const football_rounds = ["1h", "2h", "FT"];

const findClosestAsianHandicapPair = (outcome) => {
  const homeOutcomes = outcome.filter((item) => item.reference_id.includes(";1"));
  const awayOutcomes = outcome.filter((item) => item.reference_id.includes(";2"));

  let minDistance = Infinity;
  let selectedHandicap = 0;

  homeOutcomes.forEach((home) => {
    const homeHandicapValue = parseFloat(home.reference_id.match(/[-+]?\d*\.?\d+/)[0]);
    if (homeHandicapValue == 0) return;
    const complementaryValue = homeHandicapValue;
    const exactAway = awayOutcomes.find(
      (away) => parseFloat(away.reference_id.match(/[-+]?\d*\.?\d+/)[0]) === complementaryValue
    );

    if (exactAway) {
      const homeDecimal = convertOdds(home.odds, "decimal");
      const awayDecimal = convertOdds(exactAway.odds, "decimal");
      const distance = Math.abs(homeDecimal - awayDecimal);

      if (distance < minDistance) {
        minDistance = distance;
        selectedHandicap = homeHandicapValue;
      }
    }
  });
  return selectedHandicap;
};

const findClosestOddsPair = (outcome) => {
  const overOutcomes = outcome.filter((item) => item.reference_id.includes("O"));
  const underOutcomes = outcome.filter((item) => item.reference_id.includes("U"));

  let minDistance = Infinity;
  let selectedGoalValue = 0;

  overOutcomes.forEach((over) => {
    const goalValue = parseFloat(over.reference_id.match(/[-+]?\d*\.?\d+/)[0]);
    const correspondingUnder = underOutcomes.find((under) => under.reference_id.includes(goalValue));

    if (correspondingUnder) {
      const overDecimal = convertOdds(over.odds, "decimal");
      const underDecimal = convertOdds(correspondingUnder.odds, "decimal");
      const distance = Math.abs(overDecimal - underDecimal);

      if (distance < minDistance) {
        minDistance = distance;
        selectedGoalValue = goalValue;
      }
    }
  });

  return selectedGoalValue;
};

const outcomeFrom = (event, headerItem) => {
  const { title: itemTitle, market_id, reference_id } = headerItem;
  let outcome = {};
  let total = 2,
    handicap = 1.5;
  if (!event.outcome) {
    outcome = { odds: "" };
  } else if (market_id === 2 || market_id === 3) {
    // moneyline || double chance
    outcome = event.outcome.find((b) => b.reference_id === reference_id);
  } else if (market_id === 11) {
    total = findClosestOddsPair(event.outcome.filter((b) => b.market_id === 11));
    // if (itemTitle === "TOTAL") {
    if (itemTitle.toLowerCase() === "total") {
      outcome = { odds: total };
    } else {
      outcome = event.outcome.find((b) => b.reference_id === "Total," + total + headerItem.reference_id);
    }
  } else if (market_id === 7) {
    handicap = findClosestAsianHandicapPair(event.outcome.filter((b) => b.market_id === 7));
    // if (itemTitle === "HANDICAP") {
    if (itemTitle.toLowerCase() === "handicap") {
      outcome = {
        odds: handicap < 0 ? "" + handicap + "+" : "+" + handicap + "-",
      };
    } else {
      outcome = event.outcome.find(
        (b) => b.reference_id === "Handicap," + (handicap < 0 ? handicap : handicap) + headerItem.reference_id
      );
    }
  }

  return outcome ?? { odds: "" };
};

const OutcomeBox = (props) => {
  const { event, headerItem, handleOddClick, index } = props;
  const { reference_id } = headerItem;
  const outcome = useMemo(() => outcomeFrom(event, headerItem), [event, headerItem]);
  const betValue = outcome.odds;
  const oddsFormat = useSelector(selectUserOddsFormat);
  const tickets = useSelector(selectTickets);
  const timerRef = useRef();
  const [trend, setTrend] = useState("");

  const selected = useMemo(
    () => tickets.some((t) => t.id === event.sportEvent.id && t.outcome_reference_id === outcome.reference_id),
    [tickets, event, outcome]
  );

  useEffect(() => {
    setTrend(outcome.trend);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTrend("");
    }, 10000);
    return () => clearTimeout(timerRef.current);
  }, [outcome.trend]);

  const outcomeLocked = !(betValue && outcome.active);

  return (
    <Box
      className="w-full flex justify-center items-stretch px-[4px]"
      aria-label="OutcomeBox"
      sx={{
        borderRight: shouldAddDivider(headerItem.position) ? "1px solid grey" : "none",
      }}
    >
      {headerItem.position ? (
        <button
          className="w-full py-[8px] border rounded-[4px] overflow-hidden text-center hover:bg-[#00000010] disabled:bg-transparent data-[selected=true]:bg-[green] data-[selected=true]:text-white relative"
          data-selected={selected}
          onClick={(e) => handleOddClick(e, index, outcome)}
          disabled={!betValue || !outcome.active}
        >
          <Typography variant="body1">
            {!outcomeLocked ? convertOdds(betValue, oddsFormat) : <LockIcon className="text-[#00000080]" />}
          </Typography>

          {!outcomeLocked && trend === "up" ? (
            <Box aria-label="up-trend-box" className="up-trend-box"></Box>
          ) : trend === "down" ? (
            <Box aria-label="down-trend-box" className="down-trend-box"></Box>
          ) : null}
        </button>
      ) : (
        <Box className="p-[10px] w-full text-center bg-[#00000010] text-[#00000080]">
          <Typography>{isNaN(betValue) ? betValue : +(+betValue).toFixed(2)}</Typography>
        </Box>
      )}
    </Box>
  );
};

const OutcomeBoxMobile = (props) => {
  const { event, headerItem, handleOddClick, index } = props;
  const { reference_id } = headerItem;
  const outcome = useMemo(() => outcomeFrom(event, headerItem), [event, headerItem]);
  const betValue = outcome.odds;
  const oddsFormat = useSelector(selectUserOddsFormat);
  const tickets = useSelector(selectTickets);
  const timerRef = useRef();
  const [trend, setTrend] = useState("");
  const currentLanguage = useSelector(selectCurrentLanguage);

  const selected = useMemo(
    () => tickets.some((t) => t.id === event.sportEvent.id && t.outcome_reference_id === outcome.reference_id),
    [tickets, event, outcome]
  );

  useEffect(() => {
    setTrend(outcome.trend);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTrend("");
    }, 10000);
    return () => clearTimeout(timerRef.current);
  }, [outcome.trend]);

  const outcomeLocked = !(betValue && outcome.active);

  if (!headerItem.position) return (
    <Box className="p-[10px] w-full text-center bg-[#00000010] text-[#00000080]">
      <Typography>{isNaN(betValue) ? betValue : +(+betValue).toFixed(2)}</Typography>
    </Box>
  );

  return (
    <Box
      className="w-full flex justify-center items-center"
      sx={{
        borderRight: shouldAddDivider(headerItem.position) ? "1px solid grey" : "none",
        paddingRight: shouldAddDivider(headerItem.position) ? "4px" : "none",
      }}
    >
      <button
        className="border border-[#e2e8f0] rounded-[8px] text-center hover:bg-[#00000010] data-[selected=true]:bg-[green] data-[selected=true]:text-white flex items-center"
        data-selected={selected}
        onClick={(e) => handleOddClick(e, index, outcome)}
        disabled={!betValue || !outcome.active}
      >
        <Box className="py-[10px] px-[12px] border-r">
          <Typography>{i18next.t(headerItem.title)}</Typography>
        </Box>
        <Box className="py-[10px] px-[12px] relative">
          <Typography variant="body1" sx={{ marginRight: "5px" }}>
            {!outcomeLocked ? convertOdds(betValue, oddsFormat) : <LockIcon className="text-[#00000080]" />}
          </Typography>

          {!outcomeLocked && trend === "up" ? (
            <Box aria-label="up-trend-box" className="up-trend-box"></Box>
          ) : trend === "down" ? (
            <Box aria-label="down-trend-box" className="down-trend-box"></Box>
          ) : null}
        </Box>
      </button>
    </Box>
  );
};

const SportEvent = ({ event, onClick, sport_name }) => {
  const dispatch = useDispatch();
  const favEvents = useSelector(selectFavEvents);
  // const [isFavorite, setIsFavorite] = useState(false);
  const [width, setWidth] = useState(0);
  const containerRef = useRef(null);
  const isSm = useScreenSm();
  const { isLive } = useIsLive();

  const circleRef = useRef(null);
  const tickets = useSelector((state) => state.sportsApp.tickets.selectedOdds);
  const badgePosition = useSelector(selectBadgetPosition);
  const title = betTypeTitles[event.type];
  // const selectedSport = useSelector(getselectedSport);
  const betType = useSelector(selectBetType);
  const selectedTickets = useSelector(selectTickets);

  // const headerItems = sportsEventHeaderItems[sport_name] || [];
  const headerItems = sportsEventHeaderItems[event.sportEvent.sport_id] || [];

  const eventName = event.sportEvent?.name ?? '';
  const [team1, team2] = eventName.split(" vs ") || [];

  const marketCount = useMemo(() => {
    if (!event.outcome) return 0;
    return event.total_count
    // const marketIds = new Set();
    // for (const outcome of event.outcome) {
    //   marketIds.add(outcome.market_id);
    // }
    // return marketIds.size;
  }, [event]);

  const isFavorite = useMemo(() => {
    const selectedEvent = favEvents.find((e) => e.sportEvent.id == event.sportEvent.id);
    return !!selectedEvent;
  }, [favEvents, event.outcome]);

  // State to track original odds

  // useEffect(() => {
  // const selectedEvent = favEvents.find((e) => e.sportEvent.id == event.sportEvent.id);
  // setIsFavorite(!selectedEvent ? false : true);

  // Set the original odds only if they have not been set yet
  // }, [favEvents, event.outcome]);

  // useEffect(() => {
  //   let newSelectedOdd = [];
  //   const existingTickets = selectedTickets.filter(
  //     (ticket) =>
  //       ticket.id === event.sportEvent.id &&
  //       (ticket.market_id == 731 || ticket.market_id == 1122 || ticket.market_id == 696 || ticket.market_id == 679)
  //   );
  //   if (existingTickets.length == 0) {
  //     setSelectedOdd([]);
  //   } else {
  //     let total = 2,
  //       handicap = 0.5;
  //     headerItems.forEach((item, index) => {
  //       let existingTicket = {};
  //       if (item.market_id == 731 || item.market_id == 696) {
  //         existingTicket = existingTickets.find((b) => b.outcome_reference_id === item.reference_id);
  //       } else if (item.market_id == 1122) {
  //         if (item.title != "TOTAL") {
  //           existingTicket = existingTickets.find((b) => b.outcome_reference_id === item.reference_id + total);
  //         }
  //       } else if (item.market_id == 679) {
  //         if (item.title == "HANDICAP") {
  //         } else if (isAsianHome(item)) {
  //           existingTicket = existingTickets.find((b) =>
  //             b.outcome_reference_id === item.reference_id + handicap < 0 ? handicap : "+" + handicap
  //           );
  //         } else if (isAsianAway(item)) {
  //           existingTicket = existingTickets.find((b) =>
  //             b.outcome_reference_id === item.reference_id + handicap < 0 ? handicap * -1 : "-" + handicap
  //           );
  //         }
  //       }
  //       if (existingTicket) {
  //         newSelectedOdd.push(index);
  //       }
  //     });
  //   }
  //   setSelectedOdd(newSelectedOdd);
  // }, [selectedTickets, headerItems, event.sportEvent.id]);

  const handleFavoriteToggle = (e) => {
    e.stopPropagation();
    
    if (!isFavorite) {
      dispatch(addFavoriteEvent(event));
    } else {
      dispatch(removeFavoriteEvent(event.sportEvent.id));
    }
  };

  const onResize = () => {
    const header = containerRef.current;
    if (!header) return;
    const { width } = header.getBoundingClientRect();
    setWidth(width);
  };

  const showCirleAnimation = (e) => {
    const circle = circleRef.current;
    if (circle) {
      const { x, y, width, height } = e.currentTarget.getBoundingClientRect();
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      const { x: badgeX, y: badgeY } = badgePosition;

      circle.style.opacity = 0.5;
      circle.style.left = `${centerX}px`;
      circle.style.top = `${centerY}px`;
      circle.style.visibility = "visible";

      setTimeout(() => {
        circle.classList.add("transition-all", "duration-500");
        circle.style.left = `${badgeX}px`;
        circle.style.top = `${badgeY}px`;
        circle.style.opacity = 0;

        setTimeout(() => {
          circle.classList.remove("transition-all", "duration-500");
          circle.style.opacity = 0;
        }, 500);
      }, 100);
    }
  };

  const handleOddClick = (e, index, outcome) => {
    e.stopPropagation();
    
    const existingTickets = tickets.filter(
      (ticket) => ticket.id === event.sportEvent.id && ticket.outcome_reference_id == outcome.reference_id
    );
    if (existingTickets.length != 0) {
      existingTickets.forEach((existingTicket) => {
        const data = {
          id: existingTicket.id,
          outcome_reference_id: existingTicket.outcome_reference_id,
        };
        dispatch(removeTicket(data));
      });
    }

    // showCirleAnimation(e);

    if (existingTickets.length == 0) {
      const data = {
        id: event.sportEvent.id,
        sport_name: event.sportEvent.sport_name,
        event_reference_id: event.sportEvent.reference_id,
        outcome_reference_id: outcome.reference_id,
        outcome_id: outcome.id,
        market_id: outcome.market_id,
        active: outcome.active,
        date: event.sportEvent.start_at,
        homeTeam: team1,
        awayTeam: team2,
        odds: outcome.odds,
        detail: outcome.reference_id,
        state_amout: 0,
        game_type: event.sportEvent.status,
        tmr_update: event.sportEvent.tmr_update,
        period: event.sportEvent.period,
        outcome_id_dic: outcome.outcome_id,
        collection_info_id: outcome.collection_info_id
      };
      dispatch(addTicket(data));
    }
  };

  useEffect(() => {
    const header = containerRef.current;
    if (!header) return;
    const observer = new ResizeObserver(onResize);
    observer.observe(header);
    return () => observer.disconnect();
  }, []);

  const { sportEvent: se } = event;

  const gameTime = useGameTimer({
    tmr: se.tmr,
    tmrRunning: se.tmr_running,
    tmrUpdate: se.tmr_update,
    tmrSecond: se.tmr_second
  });

  return (
    <Box
      aria-label="SportEvent" ref={containerRef} className="border rounded-[18px] mt-[10px] cursor-pointer"
      onClick={onClick}
    >
      <Box className="flex p-[4px] xs:p-[10px] hover:bg-[#00000005] transition-all duration-100">
        <Box className="flex items-center w-[220px] xs:w-[260px] min-w-[220px] xs:min-w-[260px] flex-grow">
          <Box className="flex flex-col items-center">
            {/* <PushPinOutlinedIcon className="text-gray-700" fontSize="small" /> */}
            <IconButton onClick={handleFavoriteToggle}>
              {isFavorite ? (
                <StarIcon sx={{ color: "orange" }} fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
          <Box className="flex-grow">
            <Box className="w-full max-w-full">
              <Box className="flex justify-between items-center w-full pr-[12px]">
                {se.status !== "Live" && (
                  <Typography variant="subtitle2" className="text-[12px]">
                    {dateToText(event.sportEvent.start_at)}
                  </Typography>
                )}
                {se.status === "Live" && (
                  <Box className="flex items-center gap-[4px]">
                    <Typography variant="subtitle2" className="text-[10px] px-[4px] text-white bg-[#EB5E58] rounded-[2px]">
                      LIVE
                    </Typography>
                    {se.tmr && <Typography className="text-[11px]">{gameTime}</Typography>}
                  </Box>
                )}
                {event.sportEvent.round_info && (
                  <Typography variant="subtitle2" className="text-[10px]">
                    {event.sportEvent.sport_id === 79
                      ? event.sportEvent.round_info
                          .split(",")
                          .map((info, index) => {
                            return `${football_rounds[index]} ${info.split("-").join(":")}`;
                          })
                          .join(" | ")
                      : `${event.sportEvent.round_info.split(",").length} Set`}
                  </Typography>
                )}
              </Box>
              <Box className="flex items-center gap-[4px] w-[220px] mt-[4px]">
                <Box className="flex-grow">
                  <Box className="flex items-center gap-[4px]">
                    <CustomImage
                      src={event.sportEvent.home_logo}
                      alt="logo"
                      // alturl="/assets/images/sport-default.png"
                      width={20}
                      height={20}
                      className="w-auto h-[20px]"
                    />
                    <Typography variant="body1" className="text-[11px] truncate" style={{ maxWidth: isLive ? "80px" : "200px" }}>
                      {team1}
                    </Typography>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <CustomImage
                      src={event.sportEvent.away_logo}
                      alt="logo"
                      // alturl="/assets/images/sport-default.png"
                      width={20}
                      height={20}
                      className="w-auto h-[20px]"
                    />
                    <Typography variant="body1" className="text-[11px] truncate" style={{ maxWidth: isLive ? "80px" : "200px" }}>
                      {team2}
                    </Typography>
                  </Box>
                </Box>
                {event.sportEvent.status === "Live" && (
                  <Box className="flex-grow-0 flex gap-[8px] ml-auto mr-[8px]">
                    {event.sportEvent.sport_name !== "Football" &&
                      event.sportEvent.round_info &&
                      event.sportEvent.round_info.length > 0 &&
                      event.sportEvent.round_info.split(",").map((info, index) => {
                        const [homeScore, awayScore] = info.split("-");
                        return (
                          <Box key={index}>
                            <Typography className="text-[11px]">{homeScore}</Typography>
                            <Typography className="text-[11px]">{awayScore}</Typography>
                          </Box>
                        );
                      })}
                    <Box>
                      <Typography className="text-green-600 text-[11px]">{event.sportEvent.home_score}</Typography>
                      <Typography className="text-green-600 text-[11px]">{event.sportEvent.away_score}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Box className="fx-button fx-flat">
              <EqualizerIcon className="text-grey-700" fontSize="small" />
            </Box>
            <ExpandMoreOutlinedIcon className="text-grey-700" fontSize="small" /> */}
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem className="mr-[8px]" />
        <Box className="flex items-center justify-between w-full">
          {isSm &&
            headerItems
              ?.filter((_, index) => sportsEventHeaderItemMinWidths[index] < width)
              .map((item, index) => (
                <OutcomeBox key={index} index={index} event={event} headerItem={item} handleOddClick={handleOddClick} />
              ))}
        </Box>
        <Divider orientation="horizontal" className="mr-[8px]" />
        <Box sx={{ display: "flex", alignItems: "center", minWidth: "40px" }}>
          <Box variant="body2" className="whitespace-nowrap w-full text-center text-[12px]">
            {event.marketCount}
            <Box className="flex items-center text-[14px]">
              <AddIcon fontSize="small" className="text-[14px]" />
              {Math.max(marketCount - 3, 0)}
            </Box>
          </Box>
        </Box>
        <Box
          className="fixed left-[500px] top-[300px] z-[1000] w-[20px] h-[20px] rounded-full bg-[#ffce06] invisible -translate-x-1/2 -translate-y-1/2 opacity-50"
          aria-label="animationCirle"
          ref={circleRef}
        ></Box>
      </Box>
      {!isSm && (
        <Box className="p-[4px] xs:p-[10px]">
          <HorizontalScroll>
            <Box className="flex items-center justify-between w-full gap-[8px]">
              {headerItems.map((item, index) => (
                <OutcomeBoxMobile
                  key={index}
                  index={index}
                  event={event}
                  headerItem={item}
                  handleOddClick={handleOddClick}
                />
              ))}
            </Box>
          </HorizontalScroll>
        </Box>
      )}
    </Box>
  );
};

export default SportEvent;
