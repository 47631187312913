import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box,
  Typography, Chip, TablePagination
} from "@mui/material";
import { format } from "date-fns";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles";
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";
// Chip component for status display
const StatusChip = ({ status }) => {
  return (
    <Chip label={status === "active" ? "Active" : "Inactive"} color={status === "active" ? "primary" : "default"} />
  );
};

// Styled TableCell to match the sidebar header
const StyledTableCell = styled(TableCell)(({ theme, textAlign }) => ({
  backgroundColor: "#0d1d34",
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign,
  padding: '16px 24px',
  whiteSpace: 'nowrap'
}));

// Define columns with widths
const columns = [
  { id: "bonus", label: "Bonus", width: '15%', align: 'left' },
  { id: "status", label: "Status", width: '10%', align: 'left', value: (row) => <StatusChip status={row.status} /> },
  { id: "expire", label: "Expire", width: '10%', align: 'left', value: (row) => format(new Date(row.expire), "MM-dd-yyyy") },
  { id: "amount", label: "Amount", width: '10%', align: 'left' },
  { id: "wageringReq", label: "Wagering_Requirement", width: '10%', align: 'left' },
  { id: "amountWagered", label: "Amount_Wagered", width: '10%', align: 'left' }
];

export const BonusTable = ({rows}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowData, setRowData] = useState(rows || []);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateExpireDate = (createdAt, expireDays) => {
    const createdDate = new Date(createdAt); // Parse the created_at date
    const expireDate = new Date(createdDate.getTime() + expireDays * 24 * 60 * 60 * 1000); // Add expire days
    const formattedExpireDate = `${(expireDate.getMonth() + 1).toString().padStart(2, '0')}-${expireDate
      .getDate()
      .toString()
      .padStart(2, '0')}-${expireDate.getFullYear()}`; // Format the date as MM-dd-yyyy
    return formattedExpireDate;
  };

  // Calculate paginated rows
  const paginatedRows = rows.length >= 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

  // Mobile view layout
  // if (isMobile) {
  //   return (
  //     <Box>
  //       {paginatedRows.map((row) => (
  //         <Box key={row.id} sx={{ marginBottom: 2, padding: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
  //           {columns.map((column) => (
  //             <Box key={column.id} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
  //               <Typography variant="subtitle2">{column.label}</Typography>
  //               <Typography variant="body2">
  //                 {column.value ? column.value(row) : row[column.id]}
  //               </Typography>
  //             </Box>
  //           ))}
  //         </Box>
  //       ))}
  //       <TablePagination
  //         component="div"
  //         count={rows.length}
  //         page={page}
  //         onPageChange={handleChangePage}
  //         rowsPerPage={rowsPerPage}
  //         onRowsPerPageChange={handleChangeRowsPerPage}
  //       />
  //     </Box>
  //   );
  // }

  // Desktop view layout
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} style={{ width: column.width }} textAlign={column.align}>
                <Typography variant="subtitle2">{i18next.t(column.label)}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {i18next.t(row.bonus.name)}
              </TableCell>
              <TableCell>
                {<StatusChip status={row.status} />}
              </TableCell>
              <TableCell>
                {calculateExpireDate(row.created_at, row.bonus.expire_days)}
              </TableCell>
              <TableCell>
                {row.amount.toFixed(2)}
              </TableCell>
              <TableCell>
                {row.threshold.toFixed(2)}
              </TableCell>
              <TableCell>
                {row.current_completed}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
