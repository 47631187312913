import { Box } from "@mui/material";
import { useEffect } from "react";
import { useRef } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useScreenSm } from "../hooks/useScreens";

export const HorizontalScroll = ({ children }) => {
  const isSm = useScreenSm();
  const containerRef = useRef(null);
  const startXRef = useRef(0);
  const mouseDownRef = useRef(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.addEventListener("wheel", onWheel);
    window.addEventListener("mouseup", onMouseUp);
    return () => {
      container.removeEventListener("wheel", onWheel);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const onMouseDown = (e) => {
    startXRef.current = e.clientX;
    mouseDownRef.current = true;
  };

  const onMouseMove = (e) => {
    const deltaX = e.clientX - startXRef.current;
    if (mouseDownRef.current) {
      const container = containerRef.current;
      if (!container) return;
      container.scrollBy({ left: -deltaX, behavior: "auto" });
      startXRef.current = e.clientX;
    }
  };

  const onMouseUp = () => {
    mouseDownRef.current = false;
  };

  const onWheel = (e) => {
    e.preventDefault();
    const container = containerRef.current;
    if (!container) return;
    container.scrollLeft += e.deltaY;
  };

  const handleLeftArrowClick = () => {
    const container = containerRef.current;
    if (!container) return;
    container.scrollBy({ left: -500, behavior: "smooth" });
  }

  const handleRightArrowClick = () => {
    const container = containerRef.current;
    if (!container) return;
    container.scrollBy({ left: 500, behavior: "smooth" });
  }

  return (
    <Box className="relative horizontal-scroll-box w-full">
      {isSm && (
        <button
          className="hover:bg-[#4da53310] absolute left-0 -translate-x-full top-1/2 -translate-y-1/2 z-20 opacity-0"
          onClick={handleLeftArrowClick}
        >
          <KeyboardArrowLeftIcon className="text-[#4da533]" />
        </button>
      )}
      <Box
        aria-label="HorizontalScroll"
        className="w-full flex overflow-x-auto"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        ref={containerRef}
        sx={{ scrollbarWidth: "none" }}
      >
        {children}
      </Box>
      {isSm && (
        <button
          className="hover:bg-[#4da53310] absolute right-0 translate-x-full top-1/2 -translate-y-1/2 z-20 opacity-0"
          onClick={handleRightArrowClick}
        >
          <KeyboardArrowRightIcon className="text-[#4da533]" />
        </button>
      )}
    </Box>
  );
};
