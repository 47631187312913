import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";
import i18n from "src/i18n";


function FooterText() {
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  return (
    <>
      <div className="footer_text">
        <p className="break-words text-center"> 
          {i18n.t('In_order_to_register_for_this_website,_the_user_is_required_to_accept_the General_Terms')} &amp; {i18n.t('Conditions_In_the_event_the_General_Terms')} &amp; {i18n.t('Conditions_are_updated')},
          {i18n.t('existing_users_may_choose_to_discontinue_using_the_products_and_services_before_the_said_update_shall_become_effective,_which_is_a_minimum_of_two_weeks after_it_has_been_announced')}
        </p>
      </div>
      <div className="copyright">
        <p> {i18n.t('©_2018-2024_All_Rights_Reserved')} </p>
      </div>
    </>
  );
}

export default FooterText;