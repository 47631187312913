import { Box, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScreenMd } from "src/app/hooks/useScreens";
import { CasinoCard } from "src/app/main/casino/components/CasinoCard";
import { getCasinoTypes, getGamesByVendor } from "src/app/main/casino/store/gameSlice";
import { CardBox } from "src/app/main/dashboard/components/CardBox";
import { selectCasinoTypes } from "src/app/main/store/uiSlice";
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const buttonList = [
  { id: "casino", tutle: "Casino", translate: "Casino" },
  { id: "live-casino", title: "Live_Casino", translate: "Live_Casino" },
];

export const CasinoContainer = ({ casinoTab, liveCasinoTab }) => {
  const [tab, setTab] = useState("casino");
  const isMd = useScreenMd();
  const [allCasinoTypes, setAllCasinoTypes] = useState([]);
  const [casinoItems, setCasinoItems] = useState([]);
  const dispatch = useDispatch();
  const [evolutionGames, setEvolutionGames] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const theme = useTheme();

  const casinoTypes = useMemo(() => {
    if (!allCasinoTypes) return [];
    
    const type =
      tab === "casino"
        ? "Casino"
        : tab === "live-casino"
        ? "Live Casino"
        : tab === "virtual-games"
        ? "Virtual"
        : "";
    return allCasinoTypes.filter((casino) => casino.type === type);
  }, [allCasinoTypes, tab]);

  useEffect(() => {
    dispatch(getCasinoTypes()).then(res => setAllCasinoTypes(res.payload));
    // evolution id is 86
    dispatch(getGamesByVendor(86)).then(res => {
      setEvolutionGames(res.payload || []);
    });
  }, []);

  useEffect(() => {
    const games = evolutionGames.filter(g => casinoTypes.some(t => t.name === g.type));
    setCasinoItems(games.filter((_, index) => index < 16));
  }, [casinoTypes, evolutionGames]);

  return (
    <CardBox
      header={
        <Box className="flex items-center gap-[8px]">
          {buttonList.map((b) => (
            <button
              key={b.id}
              className="text-[#ffffff80] data-[selected=true]:text-white text-[12px] uppercase font-500 hover:bg-[#ffffff20] px-[8px] py-[6px] rounded-full"
              data-selected={tab === b.id}
              onClick={() => setTab(b.id)}
            >
              {i18next.t(b.translate)}
            </button>
          ))}
        </Box>
      }
    >
      <Box
        className="rounded-b-[12px] flex flex-col min-h-[500px] max-h-[500px] overflow-y-auto"
        bgcolor={theme.palette.background.paper}
      >
        <Box className="w-full min-h-[16px]"></Box>
        <Box className="mx-auto grid grid-cols-2 gap-[16px]">
          {casinoItems
            .filter((item) => (item.mobile && !isMd) || (!item.mobile && isMd))
            .map((item, i) => (
              <Box key={i} className="w-[120px] h-[120px] min-w-[120px] min-h-[120px]">
                <CasinoCard item={item} rootPath={tab === "casino" ? "casino" : "live-casino"} />
              </Box>
            ))}
        </Box>
        <Box className="w-full min-h-[16px]"></Box>
      </Box>
    </CardBox>
  );
};
