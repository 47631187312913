import { Box, Typography } from "@mui/material";
import { SearchBox } from "app/shared-components/SearchBox";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLeagueList, selectSportsList, selectTournamentList } from "../../store/tournamentSlice";
import { flagPath, textSearch } from "src/app/utils";
import format from "date-fns/format";
import { useIsLive } from "src/app/hooks/useIsLive";
import { useNavigate } from "react-router-dom";
import { getCompetitorSearch } from "../../store/uiSlice";

export const SearchModal = ({ visible, onClose }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedSports, setSearchedSports] = useState([]);
  const [searchedLeagues, setSearchedLeagues] = useState([]);
  const [searchedEvents, setSearchedEvents] = useState([]);

  const sports = useSelector(selectSportsList);
  const leagues = useSelector(selectLeagueList);
  const tournamentData = useSelector(selectTournamentList);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLive } = useIsLive();
  const [resultTab, setResultTab] = useState("events");
  const searching = searchText.length >= 3 && searchText.length <= 50;
  
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    if (searching) {
      const searchedSports = textSearch(sports, "name", searchText);
      const searchedLeagues = textSearch(leagues, "name", searchText);
      setSearchedSports(searchedSports);
      setSearchedLeagues(searchedLeagues);

      dispatch(getCompetitorSearch({ competitor: searchText })).then((res) => {
        const data = res.payload;
        setSearchedEvents(data.sportEventsList);
      });
    }
  }, [searchText, sports, leagues]);

  const handleTabChange = (tab) => {
    setResultTab(tab);
  }

  useEffect(() => {
    if (searchedEvents.length === 0) {
      setResultTab(searchedLeagues.length === 0 ? "sports" : "leagues");
    } else {
      setResultTab("events");
    }
  }, [searchedEvents, searchedLeagues])

  const handleEventClick = (event) => {
    navigate(`/sports/detail/${event.reference_id}`);
    onClose();
  }

  const handleLeagueClick = (league) => {
    navigate(`/sports/leagues/${league.sport_id}?list=${league.country_id}-${league.id}`);
    onClose();
  }

  const handleSportClick = (sport) => {
    navigate(`/sports/${sport.name.removeSpaces()}${isLive ? "?live=true" : ""}`);
    onClose();
  }
  
  return (
    <>
      <Box
        className="fixed w-screen h-[calc(var(--app-height))] left-0 top-0 invisible data-[visible=true]:visible opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 z-[9999] bg-[#ffffff10]"
        data-visible={visible}
        onClick={onClose}
        sx={{ backdropFilter: "blur(4px)" }}
      ></Box>
      <Box
        className="hidden data-[visible=true]:flex opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 flex-col gap-[12px] fixed top-0 left-1/2 -translate-x-1/2 px-[12px] w-full max-w-[800px] mt-[120px] md:mt-[50px] z-[10000]"
        data-visible={visible}
      >
        <button className="absolute -right-[40px] -top-[4px]" onClick={onClose}>
          <CloseIcon className="text-white" fontSize="large" />
        </button>
        <SearchBox inputProps={{ value: searchText, onChange: handleSearchTextChange }} />
        {!searching && (
          <Box className="p-[24px] bg-white rounded-[12px] shadow-lg">
            <Typography>- {i18next.t("Enter_name_of_competitor_league_tournament_or_sport")}</Typography>
            <Typography>- {i18next.t("Required_length_from_3_to_50_symbols")}</Typography>
            <Typography>- {i18next.t("Search_works_both_in_your_interface_language_and_in_English")}</Typography>
          </Box>
        )}
        {searching && (
          <Box className="shadow-lg">
            <Box className="flex bg-[#2c374f] text-white rounded-t-[12px] min-h-[36px] overflow-hidden">
              {searchedEvents.length > 0 && (
                <button
                  className="px-[24px] py-[8px] text-[#aaa] data-[selected]:bg-[#212733] data-[selected]:text-white"
                  data-selected={resultTab === "events" || null}
                  onClick={() => handleTabChange("events")}
                >
                  {`Events (${searchedEvents.length})`}
                </button>
              )}
              {searchedLeagues.length > 0 && (
                <button
                  className="px-[24px] py-[8px] text-[#aaa] data-[selected]:bg-[#212733] data-[selected]:text-white"
                  data-selected={resultTab === "leagues" || null}
                  onClick={() => handleTabChange("leagues")}
                >
                  {`Leagues (${searchedLeagues.length})`}
                </button>
              )}
              {searchedSports.length > 0 && (
                <button
                  className="px-[24px] py-[8px] text-[#aaa] data-[selected]:bg-[#212733] data-[selected]:text-white"
                  data-selected={resultTab === "sports" || null}
                  onClick={() => handleTabChange("sports")}
                >
                  {`Sports (${searchedSports.length})`}
                </button>
              )}
            </Box>
            <Box className="bg-white rounded-b-[12px] min-h-[200px] flex flex-col py-[12px]">
              {resultTab === "events" && searchedEvents.map(event => (
                <button className="flex justify-between items-center px-[24px] py-[8px] hover:bg-[#00000010]" onClick={() => handleEventClick(event)}>
                  <Box className="flex items-center gap-[8px]">
                    {/* <img src={flagPath(league.flag || league.country_flag)} alt="" width={16} height={16} className="w-auto h-[16px]" /> */}
                    <Typography>{event.name}</Typography>
                  </Box>
                  <Typography>{format(new Date(event.start_at), "hh:mm, MM/dd/yyyy")}</Typography>
                </button>
              ))}
              {resultTab === "leagues" && searchedLeagues.map(league => (
                <button className="flex justify-between items-center px-[24px] py-[8px] hover:bg-[#00000010]" onClick={() => handleLeagueClick(league)}>
                  <Box className="flex items-center gap-[8px]">
                    <img src={flagPath(league.sport_flag)} alt="" width={16} height={16} className="w-auto h-[16px]" />
                    <img src={flagPath(league.flag || league.country_flag)} alt="" width={16} height={16} className="w-auto h-[16px]" />
                    <Typography>{league.name}</Typography>
                  </Box>
                  <Typography>{league.events_number}</Typography>
                </button>
              ))}
              {resultTab === "sports" && searchedSports.map(sport => (
                <button className="flex justify-between items-center px-[24px] py-[8px] hover:bg-[#00000010]" onClick={() => handleSportClick(sport)}>
                  <Box className="flex items-center gap-[8px]">
                    <img src={sport.icon} alt="" width={16} height={16} className="w-auto h-[16px]" />
                    <Typography>{sport.name}</Typography>
                  </Box>
                  <Typography>{sport.events_number}</Typography>
                </button>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
