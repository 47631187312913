import jwtServiceConfig from "../auth/services/jwtService/jwtServiceConfig";

export const TOP_SPORT_ID = -2;
export const FAV_SPORT_ID = -1;

export const TOP_SPORT = { id: TOP_SPORT_ID, name: "Top" };
export const FAV_SPORT = { id: FAV_SPORT_ID, name: "Favorite" };

export const SCREEN_XS = 375;
export const SCREEN_SM = 600;
export const SCREEN_MD = 960;
export const SCREEN_LG = 1280;
export const SCREEN_XL = 1920;

export const ODD_SETTING_ACCEPT_ANY_ODDS = 0;
export const ODD_SETTING_ACCEPT_HIGHER_ODDS = 1;
export const ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE = 2;

export const SPORT_LIST = [
    {"id": 18, "name": "billiard"},
    {"id": 19, "name": "bandy"},
    {"id": 20, "name": "curling"},
    {"id": 21, "name": "futsal"},
    {"id": 22, "name": "specials"},
    {"id": 23, "name": "boxing"},
    {"id": 24, "name": "chess"},
    {"id": 25, "name": "athletics"},
    {"id": 26, "name": "field_hockey"},
    {"id": 27, "name": "alpine_ski"},
    {"id": 28, "name": "cross_country"},
    {"id": 29, "name": "biathlon"},
    {"id": 30, "name": "cycling"},
    {"id": 31, "name": "cricket"},
    {"id": 32, "name": "beach_volley"},
    {"id": 33, "name": "beach_football"},
    {"id": 34, "name": "beach_handball"},
    {"id": 35, "name": "floorball"},
    {"id": 36, "name": "rink_hockey"},
    {"id": 37, "name": "lacrosse"},
    {"id": 38, "name": "badminton"},
    {"id": 39, "name": "golf"},
    {"id": 40, "name": "darts"},
    {"id": 41, "name": "bowls"},
    {"id": 42, "name": "cricket"},
    {"id": 43, "name": "croquet"},
    {"id": 44, "name": "softball"},
    {"id": 45, "name": "bowling"},
    {"id": 46, "name": "australian_football"},
    {"id": 47, "name": "gaelic_football"},
    {"id": 48, "name": "netball"},
    {"id": 49, "name": "squash"},
    {"id": 50, "name": "padel_tennis"},
    {"id": 51, "name": "padel_tennis"},
    {"id": 52, "name": "financial"},
    {"id": 53, "name": "sports_simulators"},
    {"id": 54, "name": "esports"},
    {"id": 55, "name": "mix_fights"},
    {"id": 56, "name": "sepak_takrow"},
    {"id": 57, "name": "poker"},
    {"id": 58, "name": "cyberfootball"},
    {"id": 59, "name": "cyberhockey"},
    {"id": 60, "name": "cyberbasket"},
    {"id": 61, "name": "lottery"},
    {"id": 62, "name": "rocket_league"},
    {"id": 63, "name": "cybertennis"},
    {"id": 64, "name": "sport"},
    {"id": 65, "name": "figure_skating"},
    {"id": 66, "name": "basketball_3x3"},
    {"id": 67, "name": "horse_racing"},
    {"id": 68, "name": "dog_racing"},
    {"id": 69, "name": "sumo"},
    {"id": 70, "name": "e-football"},
    {"id": 71, "name": "e-basketball"},
    {"id": 72, "name": "e-ice_hockey"},
    {"id": 73, "name": "racing"},
    {"id": 74, "name": "handball"},
    {"id": 75, "name": "waterpolo"},
    {"id": 76, "name": "am.football"},
    {"id": 77, "name": "volleyball"},
    {"id": 78, "name": "baseball"},
    {"id": 79, "name": "football1"},
    {"id": 80, "name": "rugby"},
    {"id": 81, "name": "winter_sports"},
    {"id": 82, "name": "table_tennis"},
    {"id": 83, "name": "hockey"},
    {"id": 84, "name": "tennis"},
    {"id": 85, "name": "basketball"}
];


export const ONLINE_SYSTEM = 1;
export const BETSHOP_SYSTEM = 2;

export const PopularPrinters = [
  { label: "A4 for Epson XC, Canon", value: "A4" },
  { label: "Letter for HP LaserJet, Brother", value: "Letter" },
  { label: "Legal for Epson WF, Canon", value: "Legal" },
  { label: "Executive for HP OfficeJet, Epson", value: "Executive" },
  { label: "Tabloid for Canon TS, Epson", value: "Tabloid" },
  { label: "A3 for Epson SureColor, Canon", value: "A3" },
];

export const PaperStyles = {
    A4: { width: '210mm', height: '297mm' },
    Letter: { width: '8.5in', height: '11in' },
    Legal: { width: '8.5in', height: '14in' },
    Executive: { width: '7.25in', height: '10.5in' },
    Tabloid: { width: '11in', height: '17in' },
    A3: { width: '297mm', height: '420mm' },
  };