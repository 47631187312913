import { Box, Button, Typography } from "@mui/material";
import { ContentCard } from "../../component/ContentCard";
import { useState } from "react";
import { SelectGroup } from "../../component/InputGroups";
import { DatePicker } from "@mui/x-date-pickers";
import { getTransactionHistory } from "../../store/historySlice";
import { CustomTable } from "app/shared-components/CustomTable";
import { selectUser } from "app/store/userSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const datePickerSx = {
  "& input": { paddingY: "3px" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ddd !important",
    borderWidth: "1px !important",
    borderRadius: "12px",
  },
  "& .MuiInputBase-root": {
    minHeight: "38px",
  },
};

const options = [
  { label: "All", value: "all" },
  { label: "Deposit", value: "Deposit" },
  { label: "Withdrawal", value: "Withdraw" },
  { label: "Deposit_Bonus", value: "Deposit_Bonus" },
  { label: "Withdraw_Bonus", value: "Withdraw_Bonus" },
  { label: "New_Coupon", value: "New_Coupon" },
  { label: "New_Bonus_Coupon", value: "New_Bonus_Coupon" },
  { label: "Coupon_Won", value: "Coupon_Won" },
  { label: "Bonus_Coupon_Won", value: "Bonus_Coupon_Won" },
  { label: "Coupon_Lost", value: "Coupon_Lost" },
  { label: "Bonus_Coupon_Lost", value: "Bonus_Coupon_Lost" },
  { label: "Coupon_Refunded", value: "Coupon_Refunded" },
  { label: "Bonus_Coupon_Refunded", value: "Bonus_Coupon_Refunded" },
  { label: "Coupon Canceled", value: "Coupon_Canceled" },
  { label: "Bonus Coupon Canceled", value: "Bonus_Coupon_Canceled" },
  { label: "Coupon Cashed Out", value: "Coupon_Cashed_Out" },
  { label: "Coupon Partial Cashout", value: "Coupon_Partial_Cashout" },
  { label: "Revert Plus", value: "Revert_Plus" },
  { label: "Revert Minus", value: "Revert_Minus" },
  { label: "Casino Out", value: "Casino_Out" },
  { label: "Casino In", value: "Casino_In" },
  { label: "Casino Rollback Debit", value: "Casino_Rollback_Debit" },
  { label: "Casino Rollback Credit", value: "Casino_Rollback_Credit" },
  { label: "Free Spin", value: "Free_Spin" },
];

const optionsForBetshop = [
  { label: "All", value: "all" },
  { label: "Deposit", value: "Deposit" },
  { label: "Withdraw", value: "Withdraw" },
  { label: "New Coupon", value: "New_Coupon" },
  { label: "Coupon Won", value: "Coupon_Won" },
  { label: "Coupon Lost", value: "Coupon_Lost" },
  { label: "Coupon Refunded", value: "Coupon_Refunded" },
  { label: "Coupon Canceled", value: "Coupon_Canceled" },
  { label: "Coupon Cashed Out", value: "Coupon_Cashed_Out" },
  { label: "Coupon Partial Cashout", value: "Coupon_Partial_Cashout" },
  { label: "Revert Plus", value: "Revert_Plus" },
  { label: "Revert Minus", value: "Revert_Minus" },
  { label: "Casino Out", value: "Casino_Out" },
  { label: "Casino In", value: "Casino_In" },
  { label: "Casino Rollback Debit", value: "Casino_Rollback_Debit" },
  { label: "Casino Rollback Credit", value: "Casino_Rollback_Credit" },
];

const columns = [
  { id: "id", label: "Transaction_ID", width: "15%", align: "left" },
  { id: "transaction_date", label: "Date", width: "20%", align: "left" },
  { id: "module", label: "Module", width: "10%", align: "left" },
  {
    id: "transaction_type",
    label: "Transaction_Type",
    width: "10%",
    align: "left",
  },
  { id: "description", label: "Description", width: "10%", align: "left" },
  { id: "amount", label: "Amount", width: "10%", align: "left" },
  { id: "currency", label: "Currency", width: "10%", align: "left" },
  { id: "opening_balance", label: "Old_Balance", width: "10%", align: "left" },
  { id: "closing_balance", label: "New_Balance", width: "10%", align: "left" },
];

export const Transaction = () => {
  const today = new Date();
  const [rows, setRows] = useState([]);
  const [transactionType, setTransactionType] = useState({ label: "All", value: "all" });
  const [startDate, setFromDate] = useState(new Date(today.setHours(0, 0, 0, 0)));
  const [endDate, setToDate] = useState(new Date(today.setHours(23, 59, 59, 999)));
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [betFilterOptions, setBetFilterOptions] = useState(options);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    if (user?.role && user.role.includes("betshop_user")) {
      setBetFilterOptions(optionsForBetshop);
    }
  }, [user])

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const handleGet = () => {
    const startDateString = new Date(startDate).toISOString().split('T')[0];
    const endDateString = new Date(endDate).toISOString().split('T')[0];
    dispatch(
      getTransactionHistory({ startDate: startDateString, endDate: endDateString, transactionType: transactionType.value })
    ).then((res) => {
      setRows(res.payload || []);
    });
  };

  return (
    <Box aria-label="Transaction">
      <ContentCard
        title={i18n.t("Transaction_History")}
        icon="assets/images/pages/settings/history.svg"
      >
        <Box className="bg-white rounded-b-[12px] p-[12px]">
          <Box className="flex flex-col gap-[12px] mx-auto min-h-[400px]">
            <Box className="flex flex-col md:flex-row justify-between items-center max-w-[1000px]">
              <Box className="flex flex-col md:flex-row gap-[12px] w-full md:w-auto">
                <SelectGroup
                  value={transactionType}
                  options={betFilterOptions}
                  onChange={(value) => setTransactionType(value)}
                />
                <DatePicker
                  value={startDate}
                  onChange={(newDate) => setFromDate(newDate)}
                  sx={datePickerSx}
                  format="yyyy-MM-dd"
                />
                <DatePicker
                  value={endDate}
                  onChange={(newDate) => setToDate(newDate)}
                  sx={datePickerSx}
                  format="yyyy-MM-dd"
                />
              </Box>
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[8px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] md: pt-0 mt-12 sm:mt-0"
                onClick={handleGet}
              >
                <Typography>{i18n.t("Get")}</Typography>
              </button>
            </Box>

            <CustomTable columns={columns} rows={rows} />
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
