import { Box, Typography, useTheme } from "@mui/material";
import i18next from "i18next";
import React from 'react'
import { memo } from "react";

export const WhiteBoard = memo(({ title, children, moreButtonHidden = false, handleMore }) => {
  const theme = useTheme();

  return (
    <Box
      className="p-[16px] md:p-[24px] flex flex-col rounded-[8px] shadow-md"
      aria-label="WhiteBoard"
      bgcolor={theme.palette.background.paper}
    >
      <Box className="flex justify-between items-center">
        <Typography className="sm:text-[20px] text-[18px] text-[#3E4049] font-bold">
          {title}
        </Typography>
        <button
          className="text-[#ec622b] uppercase p-[4px] rounded-md font-500 text-[14px] hover:bg-[#eee] active:bg-[#ddd]"
          style={{ display: moreButtonHidden ? "none" : "block" }}
          onClick={handleMore}
        >
          {i18next.t("MORE")}
        </button>
      </Box>
      <Box className="mt-[20px]">{children}</Box>
    </Box>
  );
});