import { Box } from '@mui/material'
import React from 'react'
import { memo } from 'react'
import { VerticalDrawer } from './VerticalDrawler'
import i18next from 'i18next'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTopLeagues } from '../../store/topLeaguesSlice'
import { selectSportsList } from '../../store/tournamentSlice'
import { flagPath } from 'src/app/utils'
import { selectTimerFilterValue } from '../../store/uiSlice'

const football_id = 79;
const basketball_id = 85;
const tennis_id = 84;

const popularSportIds = [football_id, basketball_id, tennis_id];

export const VerticalDrawerContainer = memo(() => {
  const [popularLeagues, setPopularLeagues] = useState([]);
  const dispatch = useDispatch()
  const sportList = useSelector(selectSportsList);
  const timerFilterValue = useSelector(selectTimerFilterValue);

  useEffect(() => {
    setPopularLeagues([]);
    for (const sportId of popularSportIds) {
      dispatch(
        getTopLeagues({
          languageId: "en",
          sport_id: sportId,
          betType: "unplayed",
          timerFilterValue
        })
      ).then((res) => {
        setPopularLeagues((prev) => [...prev, ...(res.payload || [])]);
      });
    }
  }, [timerFilterValue]);

  return (
    <Box className="max-h-[calc(var(--app-height)-280px)] overflow-y-auto pb-[20px] px-[8px]">
      <Box className="rounded-[12px] flex flex-col shadow-lg">
        <VerticalDrawer
          title={i18next.t("Popular")}
          items={[...popularLeagues]
            .sort((a, b) => a.tournament_order - b.tournament_order)
            .map((league) => ({
              name: league.tournament_name,
              icon: flagPath(
                league.tournamet_flag || league.country_flag
              ),
              path: `/sports/leagues/${league.sport_id}?list=${league.country_id}-${league.id}`,
            }))}
        />
        <VerticalDrawer
          title={i18next.t("All_Sports")}
          items={sportList.map((s) => ({
            ...s,
            path: `/sports/${s.name.removeSpaces()}`,
          }))}
        />
      </Box>
    </Box>
  )
})