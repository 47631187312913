import { Box } from "@mui/material";
import { useState } from "react";

export const CustomImage = (props) => {
  const [url, setUrl] = useState(props.src);
  const [error, setError] = useState(false);

  const handleError = () => {
    setUrl(props.alturl);
    setError(true);
  };

  if (error && !props.alturl)
    return (
      <Box className="p-[2px]" style={{ width: `${props.width}px`, height: `${props.height}px` }}>
        <Box className="w-full h-full rounded-full bg-[#0c606660]"></Box>
      </Box>
    );

  return <img {...props} src={url} onError={handleError} />;
};
