import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getDemoGame, getGameWithPlayerCheck, getRefreshToken } from "../store/gameSlice";
import { updateBalance } from "app/store/userSlice";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export const CasinoGame = ({ demo }) => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [gameUrl, setGameUrl] = useState('');
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [supportedCurrency, setSupportedCurrency] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    setGameUrl("");
    if (demo) {
      dispatch(getDemoGame({ lang: "en", gameId, currency: "USD" })).then((res) => {
        const data = res.payload;
        setGameUrl(data.response);
        if (data.response === "") showAlert("Demo");
      });
    } else {
      dispatch(getGameWithPlayerCheck({ lang: "en", gameId })).then((res) => {
        const data = res.payload;
        if (data.action_required) {
          handleCurrencyMismatch(data.supported_currency);
        } else {
          setGameUrl(data.response);
          if (data.response === "") showAlert("Game");
        }
      });
    }
  }, [gameId, demo]);

  const handleCurrencyMismatch = (currency) => {
    setSupportedCurrency(currency);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleCloseGame = () => {
    dispatch(getRefreshToken())
      .then(({ payload }) => {
        dispatch(updateBalance(payload.user.account_balance));
      })
  };

  const handleProceed = () => {
    setOpenDialog(false);
    dispatch(getGameWithPlayerCheck({ lang: "en", gameId, use_supported_currency: true })).then((res) => {
      const data = res.payload;
      setGameUrl(data.response);
      if (data.response === "") showAlert("Game");
    });
  };

  const showAlert = (type) => {
    toast.error(`${type} not supported`);
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  }

  return (
    <Box>
      <Box className="fixed left-0 top-0 h-[calc(var(--app-height)-65px)] z-[2000] md:left-auto md:top-auto md:flex md:h-[calc(var(--app-height)-134px)] w-full flex justify-center items-center">
        <Box className="w-full h-full md:w-auto md:h-auto md:p-[16px] md:rounded-[12px] rounded-tr-0 relative bg-white" style={{ boxShadow: "0 0 5px rgba(0,0,0,.3)" }}>
          <Box className={`${isFullScreen ? 
            "md:w-[1000px] md:h-auto h-full lg:w-[99vw] lg:h-[98vh] md:aspect-video relative lg:-top-[65px]" : 
            "md:w-[1000px] lg:w-[1000px] xl:w-[1200px] h-full md:h-auto md:aspect-video"}`}>
            <button
              onClick={() => navigate(-1)}
              className={`hidden md:block p-[4px] absolute ${isFullScreen ? "right-[40px] top-[20px] rounded-[12px]" : "right-0 top-0 rounded-r-[12px]"} translate-x-full bg-white`}
              style={{ boxShadow: "0 0 5px rgba(0,0,0,.3)" }}
            >
              {!isFullScreen && (
                <Box className="absolute left-0 top-0 w-[12px] -translate-x-full h-full bg-white z-[10]" />
              )}
              <CloseIcon onClick={handleCloseGame} />
            </button>
            <button
              onClick={handleFullScreen}
              className={`hidden md:block p-[4px] max-h-screen absolute ${isFullScreen ? "right-[40px] top-[60px] rounded-[12px]" : "right-0 top-[30px] rounded-r-[12px]"} translate-x-full bg-white`}
              style={{ boxShadow: "0 0 5px rgba(0,0,0,.3)" }}
            >
              {!isFullScreen && (
                <Box className="absolute left-0 top-0 w-[12px] -translate-x-full h-full bg-white z-[10]" />
              )}
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </button>
            {gameUrl !== "" && <iframe width="100%" height="100%" src={gameUrl}></iframe>}
          </Box>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ zIndex: 3000 }} >
        <DialogTitle>Currency Mismatch</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This game is only supported in {supportedCurrency}. Would you like to play in {supportedCurrency}? Your balance will be converted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            No
          </Button>
          <Button onClick={handleProceed} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};