import { Box, Typography } from "@mui/material";
import { useState } from "react";

export const CasinoCard = ({ title, subtitle, people, image }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      aria-label="casinoCard"
      className="w-[138px]"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box className="relative w-[138px] h-[138px] rounded-[6px] overflow-hidden bg-no-repeat bg-cover">
        <img
          src={image}
          alt=""
          data-hovered={hovered}
          className="w-full h-full rounded-[6px] absolute left-0 top-0 z-0 select-none pointer-events-none data-[hovered=true]:scale-[1.2] transition-all duration-200"
        />
        <Box
          className="bg-[#00000080] rounded-full py-[4px] px-[16px] flex gap-[4px] items-center absolute right-[10px] top-[10px] text-white transition-all duration-200"
          sx={{ top: hovered ? "-30px !important" : undefined }}
        >
          <Box>P</Box>
          <Box>{people}</Box>
        </Box>
        <Box
          className="z-[500] w-full h-full absolute left-0 top-0 bg-[#000000aa] opacity-0 data-[hovered=true]:opacity-100"
          data-hovered={hovered}
        ></Box>
        <Box
          className="z-[1000] w-full h-full absolute left-0 top-[138px] data-[hovered=true]:top-0 flex flex-col gap-[8px] justify-center items-center transition-all duration-300 text-white"
          data-hovered={hovered}
        >
          <button className="text-green-600 underline">Login</button>
          <Typography>or</Typography>
          <button className="bg-[#e07d4f] py-[2px] px-[8px] rounded-full uppercase">
            SIGN UP
          </button>
          <button className="border border-white py-[2px] px-[8px] rounded-full uppercase">
            DEMO
          </button>
        </Box>
      </Box>
      <Box className="max-w-full">
        <Typography className="text-[14px] font-bold text-[#3e4049] max-w-full mt-[8px]">
          {title}
        </Typography>
      </Box>
      <Box className="max-w-full">
        <Typography className="text-[12px] font-thin text-[#646c76] max-w-full">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
