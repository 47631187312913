import { Box, Collapse, Dialog, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { StateAmountInput } from "./StateAmountInput";
import { useState } from "react";
import { InputGroup } from "src/app/main/settings/component/InputGroups";
import { useDispatch, useSelector } from "react-redux";
import { selectBetType } from "src/app/main/store/uiSlice";
import { useEffect } from "react";
import { getSystemOption, selectTickets, updateAllStakeAmount, selectBankers, createCoupon, removeAllTickets, getCouponErrorMsg, removeCouponErrorMsg, setIsBetSaving, setTotalOdds, setPossibleWinnings, setTotalBet, setPrintTickets } from "src/app/main/store/ticketSlice";
import { selectUser, updateBalance } from "app/store/userSlice";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";
import { convertOdds } from "src/app/utils";
import CircularProgress from '@mui/material/CircularProgress';
import ReactDOM from 'react-dom';
import { selectMarketDic } from "src/app/main/store/prematchSlice";
import { dateToText } from "src/app/utils";
import { getUserPreference, updateUserPreference } from "src/app/main/settings/store/profileSlice";
import {
  PopularPrinters,
  PaperStyles
} from "src/app/constants";
import { useCustomAuth } from "src/app/hooks/useAuth";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

export const TotalizeCard = (props) => {
  const { betAvailable, amountEditing, onAmountEdit, onAmountOkay, handlePrintRequest } = props;
  const betType = useSelector(selectBetType);
  const tickets = useSelector(selectTickets);
  const selectedSystemOption = useSelector(getSystemOption);
  const [commonBetAmount, setCommonBetAmount] = useState("");
  const [totalBetAmount, setTotalBetAmount] = useState(0);
  const inputId = `stake-amount-totalize`;
  const inputElement = document.getElementById(inputId);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bankers = useSelector(selectBankers);
  const [selectedPrinter, setSelectedPrinter] = useState(PopularPrinters[0]);
  const [autoPrint, setAutoPrint] = useState(false);
  const theme = useTheme();
  const [isBetting, setIsBetting] = useState(false);
  const marketDic = useSelector(selectMarketDic);
  const authenticated = useCustomAuth();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const couponErrorMsg = useSelector(getCouponErrorMsg)


  useEffect(() => {
    if (authenticated) {
      dispatch(getUserPreference()).then((res) => {
        setAutoPrint(res.payload.auto_print);
        setSelectedPrinter(PopularPrinters.find(p => p.value === res.payload.printer_type) || PopularPrinters[0]);
      });
    }
  }, [authenticated])

  useEffect(() => {
    if (tickets.length === 0) {
      setCommonBetAmount("");
      return;
    }
    const firstValue = tickets[0].stake_amount;
    let allSame = true;
    let totalBetAmount = 0;
    for (const ticket of tickets) {
      if (firstValue !== ticket.stake_amount) {
        allSame = false;
      }
      totalBetAmount += +(ticket.stake_amount || 0);
    }
    if (!allSame) {
      setCommonBetAmount("");
    }
    if (betType === "single") {
      setTotalBetAmount(totalBetAmount);
      dispatch(setTotalBet(totalBetAmount));
    }
  }, [tickets, betType]);

  useEffect(() => {
    if (betType === "combo") {
      setTotalBetAmount(commonBetAmount);
      dispatch(setTotalBet(commonBetAmount));
    } else if (betType === "system") {
      // setTotalBetAmount(+commonBetAmount * (tickets.length - bankers.length));
      setTotalBetAmount(+commonBetAmount * (selectedSystemOption?.betCount ? selectedSystemOption.betCount : tickets.length - bankers.length));
      dispatch(setTotalBet(+commonBetAmount * (selectedSystemOption?.betCount ? selectedSystemOption.betCount : tickets.length - bankers.length)));
    }
  }, [betType, commonBetAmount, tickets, bankers, selectedSystemOption]);

  const setAllStakeAmount = (value) => {
    dispatch(updateAllStakeAmount({ stake_amount: value }));
  };

  const handleCommonAmountChange = (value) => {
    setCommonBetAmount(value);
    setAllStakeAmount(value);
    dispatch(removeCouponErrorMsg());
  };

  const handleIncrease = (value) => {
    setCommonBetAmount(+(commonBetAmount ?? 0) + +value);
    setAllStakeAmount(+(commonBetAmount ?? 0) + +value);
  };

  const handleDigitClick = (digit) => {
    const inputValue = commonBetAmount ?? "";
    const cursorPos = inputElement.selectionStart;
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setCommonBetAmount(`${leftSlice}${digit}${rightSlice}`);
    setAllStakeAmount(`${leftSlice}${digit}${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos + 1;
      inputElement.focus();
    });
  };

  const handlePointClick = () => {
    const inputValue = commonBetAmount ?? "";
    const cursorPos = inputElement.selectionStart;
    if (inputValue.includes(".")) {
      setTimeout(() => {
        inputElement.selectionStart = inputElement.selectionEnd = cursorPos;
        inputElement.focus();
      });
      return;
    }
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setCommonBetAmount(`${leftSlice}.${rightSlice}`);
    setAllStakeAmount(`${leftSlice}.${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos + 1;
      inputElement.focus();
    });
  };

  const handleBackspaceClick = () => {
    const inputValue = commonBetAmount ?? "";
    const cursorPos = inputElement.selectionStart;
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setCommonBetAmount(`${leftSlice.slice(0, -1)}${rightSlice}`);
    setAllStakeAmount(`${leftSlice.slice(0, -1)}${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos - 1;
      inputElement.focus();
    });
  };

  const calculateTotalOdds = () => {
    if (betType === "single") {
      return tickets.reduce((acc, ticket) => acc + parseFloat(convertOdds(ticket.odds, "decimal")), 0).toFixed(2);
    }

    if (betType === "combo") {
      return tickets.reduce((acc, ticket) => acc * parseFloat(convertOdds(ticket.odds, "decimal")), 1).toFixed(2);
    }
    if (betType === "system" && selectedSystemOption) {
      let combinations = [];

      const getCombinations = (arr, k) => {
        const result = [];
        const f = (prefix, arr) => {
          if (prefix.length === k) {
            result.push(prefix);
            return;
          }
          for (let i = 0; i < arr.length; i++) {
            f([...prefix, arr[i]], arr.slice(i + 1));
          }
        };
        f([], arr);
        return result;
      };

      const nonBankers = tickets.filter((ticket) => !bankers.includes(ticket));
      let optionVal = selectedSystemOption.value
      if (bankers.length > 0) {
        optionVal = selectedSystemOption.value.includes('-')
          ? selectedSystemOption.value.split('-')[1].trim()
          : optionVal;
      }

      switch (optionVal) {
        case "Patent":
          combinations = getCombinations(nonBankers, 1).concat(
            getCombinations(nonBankers, 2),
            getCombinations(nonBankers, 3),
          );
          break;
        case "Trixie":
          combinations = getCombinations(nonBankers, 2).concat(getCombinations(nonBankers, 3));
          break;
        case "Yankee":
          combinations = getCombinations(nonBankers, 2).concat(
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4)
          );
          break;
        case "Lucky 15":
          combinations = getCombinations(nonBankers, 1).concat(
            getCombinations(nonBankers, 2),
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4)
          );
          break;
        case "Canadian":
          combinations = getCombinations(nonBankers, 2).concat(
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5)
          );
          break;
        case "Lucky 31":
          combinations = getCombinations(nonBankers, 1).concat(
            getCombinations(nonBankers, 2),
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5)
          );
          break;
        case "Heinz":
          combinations = getCombinations(nonBankers, 2).concat(
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5),
            getCombinations(nonBankers, 6)
          );
          break;
        case "Lucky 63":
          combinations = getCombinations(nonBankers, 1).concat(
            getCombinations(nonBankers, 2),
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5),
            getCombinations(nonBankers, 6)
          );
          break;
        case "Super Heinz":
          combinations = getCombinations(nonBankers, 2).concat(
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5),
            getCombinations(nonBankers, 6),
            getCombinations(nonBankers, 7)
          );
          break;
        case "Goliath":
          combinations = getCombinations(nonBankers, 2).concat(
            getCombinations(nonBankers, 3),
            getCombinations(nonBankers, 4),
            getCombinations(nonBankers, 5),
            getCombinations(nonBankers, 6),
            getCombinations(nonBankers, 7),
            getCombinations(nonBankers, 8)
          );
          break;
        default:
          if (optionVal.includes('Pick')) {
            combinations = getCombinations(nonBankers, 1);
            break;
          }
          const [numerator, denominator] = optionVal.split("/").map(Number);
          combinations = getCombinations(nonBankers, numerator);
          break;
      }

      const bankerOdds = bankers.reduce((acc, ticket) => acc * parseFloat(convertOdds(ticket.odds, "decimal")), 1);

      const totalOdds = combinations.reduce((acc, comb) => {
        const combOdds = comb.reduce(
          (a, ticket) => a * parseFloat(convertOdds(ticket.odds, "decimal")),
          1
        );
        return acc + (bankerOdds * combOdds);
      }, 0);
      dispatch(setTotalOdds(totalOdds.toFixed(2)));
      return totalOdds.toFixed(2);
    }
    dispatch(setTotalOdds(0));
    return 0;
  };

  const calculatePotentialWin = () => {
    if (betType === "single") {
      dispatch(setPossibleWinnings(
        tickets
          .reduce((acc, ticket) => acc + convertOdds(ticket.odds, "decimal") * parseFloat(ticket.stake_amount || 0), 0)
          .toFixed(2)
      ))
      return tickets
        .reduce((acc, ticket) => acc + convertOdds(ticket.odds, "decimal") * parseFloat(ticket.stake_amount || 0), 0)
        .toFixed(2);
    } else if (betType === "system") {
      const totalOdds = calculateTotalOdds();
      dispatch(setPossibleWinnings((totalOdds * parseFloat(commonBetAmount || 0)).toFixed(2)))
      return (totalOdds * parseFloat(commonBetAmount || 0)).toFixed(2);
    } else {
      const totalOdds = calculateTotalOdds();
      dispatch(setPossibleWinnings((totalOdds * parseFloat(totalBetAmount || 0)).toFixed(2)))
      return (totalOdds * parseFloat(totalBetAmount || 0)).toFixed(2);
    }
  };

  const handlePrintButtonClick = () => {
    const totalOdds = calculateTotalOdds();
    const potentialWin = calculatePotentialWin();

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Print Tickets</title></head><body><div id="print-root"></div></body></html>');
    printWindow.document.close();

    ReactDOM.render(
      <PrintTickets
        tickets={tickets}
        betType={betType}
        totalBetAmount={totalBetAmount}
        totalOdds={totalOdds}
        potentialWin={potentialWin}
        selectedPrinter={selectedPrinter}
        marketDic={marketDic}
      />,
      printWindow.document.getElementById('print-root')
    );

    if (autoPrint) {
      printWindow.focus();
      printWindow.print();
    }
  };

  const handleShareButtonClick = () => {
    if (!user.role || (Array.isArray(user.role) && user.role.length === 0)) {
      toast.custom(<Alert severity="error">{i18n.t("Please_login_to_bet")}</Alert>);
      return;
    }

    const currentTime = new Date().getTime();

    const bets = tickets.map((ticket) => {
      return {
        game_type: ticket.game_type,
        event_ref_id: ticket.event_reference_id,
        event_id: ticket.id,
        market_id: ticket.market_id,
        outcome_ref_id: ticket.outcome_reference_id,
        stake_amount: betType === "single" ? parseFloat(ticket.stake_amount) : 0,
        is_banker: bankers.some((banker) => banker.id === ticket.id),
        tmr_update: ticket.tmr_update != 0 ? parseInt((currentTime - new Date(ticket.tmr_update).getTime() * 1000) / 1000) : 0,
        // tmr_update: parseInt((currentTime - new Date(ticket.tmr_update).getTime() * 1000)/1000),
        period: ticket.period,
        outcome_id: ticket.outcome_id
      }
    });

    let system_bet_type = betType === "system" && selectedSystemOption ? (selectedSystemOption.value && selectedSystemOption.value.includes('-') ? selectedSystemOption.value.split('-')[1].trim() : selectedSystemOption.value) : 'Normal'
    system_bet_type = system_bet_type.replaceAll(" ", "");

    const data = {
      // coupon_type: betType === "single" ? i18n.t("Single") : betType === "combo" ? i18n.t("Combo") : i18n.t("System"),
      coupon_type: betType === "single" ? "Single" : betType === "combo" ? "Combo" : "System",
      system_bet_type: system_bet_type,
      total_stake_amount: betType === "single" ? 0 : parseFloat(commonBetAmount),
      bets: bets,
    };

    setIsBetting(true);
    dispatch(setIsBetSaving(true));

    dispatch(createCoupon(data)).then((res) => {
      setIsBetting(false);
      dispatch(setIsBetSaving(false));

      if (res?.payload?.user_balance)
        dispatch(updateBalance(res.payload.user_balance))
      if (!res.payload.err) {
        dispatch(removeCouponErrorMsg());
        setTimeout(() => {
          dispatch(removeAllTickets());
          if (user.role.includes("betshop_user")) {
            handlePrintRequest()
          }
        }, 2000)
      }
    }).catch((err) => {
      dispatch(setIsBetSaving(false));
    });
    dispatch(setPrintTickets(tickets));
  };

  return (
    <Box
      className="py-[8px] px-[8px]"
      color={theme.palette.text.primary}
    >
      <Box className="flex justify-between items-center">
        <Box className="flex flex-col gap-[2px] items-start">
          <Typography>{betType === "combo" ? i18n.t("Stake") : i18n.t("Stake_per_bet")}</Typography>
          <>
            {betType === "combo" ? (
              ""
            ) : betType === "system" ? (
              <Typography className="text-[10px]">
                {`${selectedSystemOption?.betCount} bets`}
              </Typography>
            ) : (
              <Typography className="text-[10px]">
                {`${tickets.length} bets`}
              </Typography>
            )}
          </>
        </Box>
        <InputGroup
          containerProps={{ width: "auto !important" }}
          inputProps={{
            disabled: !betAvailable,
            id: inputId,
            placeholder: i18n.t("Stake"),
            style: { maxWidth: "100px", textAlign: "right", borderColor: couponErrorMsg && '#F44336' },
            value: commonBetAmount,
            onChange: (e) => handleCommonAmountChange(e.target.value),
            onClick: onAmountEdit,
          }}
        />
      </Box>
      <Typography className="text-md text-[#F44336] text-right mt-4 capitalize" >{couponErrorMsg}</Typography>
      <Collapse in={amountEditing && betAvailable}>
        <Box className="mt-[8px]">
          <StateAmountInput
            onOkay={onAmountOkay}
            onIncrease={handleIncrease}
            onDigitClick={handleDigitClick}
            onPointClick={handlePointClick}
            onBackspaceClick={handleBackspaceClick}
          />
        </Box>
      </Collapse>
      <Box className="flex justify-between items-center mt-[16px]">
        <Typography className="text-[13px]">{i18n.t("Total_stake")}</Typography>
        <Typography className="text-[12px]">{totalBetAmount}</Typography>
      </Box>
      <Box className="flex justify-between items-center mt-[4px]">
        <Typography className="text-[14px]">{i18n.t("Total_Odds")}</Typography>
        <Typography className="text-[14px]">{calculateTotalOdds()}</Typography>
      </Box>
      <Box className="flex justify-between items-center mt-[4px]">
        <Typography className="text-[14px]">{i18n.t("Possible_Winnings")}</Typography>
        <Typography className="text-[14px]">{calculatePotentialWin()}</Typography>
      </Box>
      <button
        className="text-center py-[16px] bg-[#4da533] rounded-[8px] text-white w-full mt-[16px] flex items-center justify-center gap-[8px] disabled:bg-[#427532]"
        disabled={!betAvailable || isBetting}
        onClick={handleShareButtonClick}
        style={{ textTransform: isBetting ? "none" : "uppercase" }}
      >
        {isBetting && <CircularProgress size={16} className="text-white" />}
        {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) ? i18n.t("Login_to_Bet") : isBetting ? i18n.t("Betslip_is_being_processed") : i18n.t("Bet")}
      </button>
    </Box>
  );
};


