import { Box, styled, useTheme } from "@mui/material";
import { useState } from "react";

const SelectButton = styled("button")(({ selected, theme }) => ({
  backgroundColor: selected ? theme.palette.success.main : "transparent",
  color: selected ? theme.palette.success.contrastText : theme.palette.text.secondary,
  padding: "12px 16px",
  borderRadius: "9999px",
  textTransform: "uppercase",
  fontSize: "12px",
  width: "100%",
  "&:hover": {
    // backgroundColor: selected ? "#4bbd77" : "#4bbd7730",
  },
}));

const buttonList = ["CASINO BETS", "HIGH ROLLERS", "RARE WINS"];

export const TotalSelect = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value ?? 0);
  const theme = useTheme();

  const handleClick = (index) => {
    onChange ? onChange(index) : setLocalValue(index);
  };

  return (
    <Box
      aria-label="TotalSelect"
      className="p-[4px] rounded-full w-full"
      bgcolor={theme.palette.background.paper}
    >
      <Box className="flex gap-[4px] items-center">
        {buttonList.map((b, i) => (
          <SelectButton key={i} selected={i === (value ?? localValue)} onClick={() => handleClick(i)}>
            {b}
          </SelectButton>
        ))}
      </Box>
    </Box>
  );
};
