import React, { useState, useRef } from "react";
import { Box, Grid, Typography, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addTicket, removeTicket, selectTickets } from "../../../store/ticketSlice";
import { selectBadgetPosition, selectBetType } from "src/app/main/store/uiSlice";
import { convertOdds } from "src/app/utils";
import { selectUserOddsFormat } from "../../../store/uiSlice";
import LockIcon from "@mui/icons-material/Lock";
import { useEffect } from "react";

export const betTypeTitles = {
  moneyline: "Match Result",
  next_goal: "Next Goal",
  draw_no: "Draw No Bet",
  total: "Total Goals",
  both_score: "Both Teams To Score",
  double_chance: "Double Chance",
  handicap: "European Handicap",
  correct_score: "Correct Score",
  ht_ft: "HT/FT",
  incl_winner: "Winner (INCL. OVERTIME)",
  basketball_1x2: "1x2",
};

const betLine = ({ type, position, total, handicap, homeScore, awayScore, ht, ft }) => {
  if (type === "moneyline") return { 1: "Home", X: "Draw", 2: "Away" }[position];
  if (type === "total") return `${{ O: "Over", U: "Under" }[position]} ${total}`;
  if (type === "double_chance") return { "1X": "1X", 12: "12", "2X": "2X" }[position];
  if (type === "handicap") return `${{ H1: "H1", H2: "H2" }[position]} (${handicap})`;
  if (type === "draw_no") return { 1: "Home", X: "Draw", 2: "Away" }[position];
  if (type === "next_goal") return { 1: "Home", X: "No", 2: "Away" }[position];
  if (type === "both_score") return { yes: "Yes", no: "No" }[position];
  if (type === "correct_score") return `${homeScore}-${awayScore}`;
  if (type === "ht_ft") return `${ht}/${ft}`;
  if (type === "incl_winner") return { 1: "Home", 2: "Away" }[position];
  if (type === "basketball_1x2") return { 1: "Home", X: "Draw", 2: "Away" }[position];
};

const halfSxTypes = [
  "Team To Win Both Halves",
  "Total Corners",
  "Goal Scorer",
  "Team Clean Sheet",
  "Draw No Bet",
  "Total Goals",
  "Team Total",
  "Asian Handicap",
  "Both Teams To Score",
];

const OutcomeBox = ({ outcome, selected, handleOddClick, oddsFormat }) => {
  const timerRef = useRef();
  const [trend, setTrend] = useState(outcome.trend);

  useEffect(() => {
    setTrend(outcome.trend);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTrend("");
    }, 10000);
    return () => clearTimeout(timerRef.current);
  }, [outcome.trend]);
  
  return (
    <Box
      className="flex flex-col md:flex-row justify-between items-center px-[4px] md:px-[10px] md:py-[6px] rounded-[4px] transition-all duration-150 relative"
      sx={
        outcome.active
          ? {
              backgroundColor: selected ? "green" : "#00000030",
              color: selected ? "white" : "black",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: selected ? "green" : "#324A5F",
                color: "white",
              },
            }
          : {
              backgroundColor: selected ? "green" : "#00000030",
              color: selected ? "white" : "black",
              cursor: "pointer",
            }
      }
      onClick={outcome.active ? handleOddClick : () => {}}
    >
      <Typography variant="body1" sx={{ textAlign: "left", fontSize: { xs: "12px", md: "13px" } }}>
        {outcome.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "right",
        }}
      >
        <Typography variant="body1" sx={{ marginRight: "5px", fontSize: { xs: "12px", md: "13px" } }}>
          {outcome.active ? convertOdds(outcome.odds, oddsFormat) : <LockIcon className="text-[#00000080]" />}
        </Typography>
      </Box>
      {trend === "up" ? (
        <Box aria-label="up-trend-box" className="up-trend-box"></Box>
      ) : trend === "down" ? (
        <Box aria-label="down-trend-box" className="down-trend-box"></Box>
      ) : null}
    </Box>
  )
}

const OddsContainer = ({ type, outcomes, event, isFavorite, onFavoriteToggle }) => {
  const dispatch = useDispatch();
  const selectedTickets = useSelector(selectTickets);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedOdd, setSelectedOdd] = useState([]);
  const tickets = useSelector((state) => state.sportsApp.tickets.selectedOdds);
  const badgePosition = useSelector(selectBadgetPosition);
  const oddsFormat = useSelector(selectUserOddsFormat);

  // const title = betTypeTitles[type];
  const circleRef = useRef(null);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (tickets.length === 0) {
      setSelectedOdd([]);
    }
  }, [tickets])

  const handleOddClick = (e, index, outcome) => {
    const existingTickets = tickets.filter(
      (ticket) => ticket.id === event.id && ticket.outcome_reference_id == outcome.reference_id
    );
    
    if (existingTickets) {
      existingTickets.forEach((existingTicket) => {
        const data = {
          id: existingTicket.id,
          outcome_reference_id: existingTicket.outcome_reference_id,
        };
        dispatch(removeTicket(data));
      });
    }

    // const circle = circleRef.current;
    // if (circle) {
    //   const { x, y, width, height } = e.currentTarget.getBoundingClientRect();
    //   const centerX = x + width / 2;
    //   const centerY = y + height / 2;
    //   const { x: badgeX, y: badgeY } = badgePosition;

    //   circle.style.opacity = 0.5;
    //   circle.style.left = `${centerX}px`;
    //   circle.style.top = `${centerY}px`;
    //   circle.style.visibility = "visible";

    //   setTimeout(() => {
    //     circle.classList.add("transition-all", "duration-500");
    //     circle.style.left = `${badgeX}px`;
    //     circle.style.top = `${badgeY}px`;
    //     circle.style.opacity = 0;

    //     setTimeout(() => {
    //       circle.classList.remove("transition-all", "duration-500");
    //       circle.style.opacity = 0;
    //     }, 500);
    //   }, 100);
    // }

    // const newSelectedOdd = selectedOdd === index ? null : index;
    let newSelectedOdd = selectedOdd.includes(index)
      ? selectedOdd.filter((odd) => odd !== index)
      : [...selectedOdd, index];
    setSelectedOdd(newSelectedOdd);

    if (existingTickets.length == 0) {
      const [team1, team2] = event.name.split(" vs ");
      const data = {
        id: event.id,
        sport_id: event.sport_id,
        event_reference_id: event.reference_id,
        outcome_reference_id: outcome.reference_id,
        outcome_id: outcome.id,
        market_id: outcome.market_id,
        active: outcome.active,
        date: event.start_at,
        homeTeam: team1,
        awayTeam: team2,
        odds: outcome.odds,
        detail: outcome.reference_id,
        state_amout: 0,
        game_type: event.status,
        tmr_update: event.tmr_update,
        period: event.period,
        outcome_id_dic: outcome.outcome_id,
        collection_info_id: outcome.collection_info_id
      };
      dispatch(addTicket(data));
    }
  };

  const handleFavoriteToggle = (e) => {
    e.stopPropagation();
    onFavoriteToggle();
  };

  let active = false;
  outcomes.forEach((outcome) => {
    if (outcome.active) active = true;
  });

  return (
    active && (
      <Box className="mb-[2px] text-white overflow-auto flex flex-col">
        <Collapse in={!isCollapsed}>
          <Grid container columnSpacing={1} rowGap={1} className="bg-white p-[8px] items-center">
            {outcomes.length <= 3 && (
              <Grid item xs={outcomes.length < 3 || type.includes("Total") || type.includes("Handicap") ? 4 : 3}>
                <Typography className="text-black px-[4px] text-[12px] md:text-[13px]">{type}</Typography>
              </Grid>
            )}
            {outcomes
              .sort((a, b) => a.outcome_order - b.outcome_order)
              .map((outcome, index) => (
                <Grid
                  key={index}
                  item
                  xs={
                    outcomes.length <= 3
                      ? halfSxTypes.some((t) => type.includes(t))
                        ? outcomes.length < 3
                          ? 6
                          : 4
                        : outcomes.length < 3
                        ? 4
                        : 3
                      : 4
                  }
                >
                  <OutcomeBox
                    outcome={outcome}
                    selected={selectedOdd.includes(index)}
                    oddsFormat={oddsFormat}
                    handleOddClick={e => handleOddClick(e, index, outcome)}
                  />
                </Grid>
              ))}
          </Grid>
        </Collapse>
        <Box
          className="fixed left-[500px] top-[300px] z-[1000] w-[20px] h-[20px] rounded-full bg-[#ffce06] invisible -translate-x-1/2 -translate-y-1/2 opacity-50"
          aria-label="animationCirle"
          ref={circleRef}
        ></Box>
      </Box>
    )
  );
};

export default OddsContainer;
