import { Grid, Stack } from "@mui/material";

function FooterLogos() {
  return (
    <Stack
      gap="30px"
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        paddingBlock: '24px',
        paddingBottom: '40px'
      }}
      className="pl-10"
    >
      <Grid
        container
        rowSpacing={4}
        columnSpacing={{
          xs: 4,
          md: 12
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/Bitcoin.svg" alt="bitcoin" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/Dogecoin.svg" alt="Dogecoin" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/eos.svg" alt="Eos" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/etherium2.svg" alt="Ethereum" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/tron2.svg" alt="Tron" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/ripple2.svg" alt="Tron" />
          </a>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={{
          xs: 4,
          md: 12
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/banxa2.svg" alt="Tron" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/wert2.svg" alt="Tron" />
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a href="#" className="opacity-30 flex-1">
            <img src="assets/images/FooterLogos/moonpay2.svg" alt="Tron" />
          </a>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default FooterLogos;
