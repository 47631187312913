import { useRef } from "react";
import { useState } from "react"
import { useEffect } from "react"

export const useGameTimer = ({ tmr, tmrRunning, tmrUpdate, tmrSecond}) => {
  const [seconds, setSeconds] = useState(0);

  const tmrUpdateRef = useRef(tmrUpdate);
  const tmrSecondRef = useRef(tmrSecond);
  const tmrSecondOldRef = useRef(-1);
  const tmrRunningRef = useRef(tmrRunning);
  tmrUpdateRef.current = tmrUpdate;
  tmrSecondRef.current = tmrSecond;
  tmrRunningRef.current = tmrRunning;

  useEffect(() => {
    if (!tmrRunningRef.current && tmrUpdateRef.current) {
      setSeconds(Math.floor(Date.now() / 1000) - tmrUpdateRef.current);
      return;
    }

    const timer = setInterval(() => {
      if (tmrSecondRef.current && tmrSecondRef.current !== tmrSecondOldRef.current) {
        setSeconds(tmrSecondRef.current);
        tmrSecondOldRef.current = tmrSecondRef.current;
      } else if (tmrRunningRef.current && tmrUpdateRef.current) {
        setSeconds(Math.floor(Date.now() / 1000) - tmrUpdateRef.current);
      } else if (tmrRunningRef.current) {
        setSeconds(s => s + 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  
  const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');
  
  return `${minutes}:${secs}`;
}