import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Stack,
  TableContainer,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { SearchBox } from "app/shared-components/SearchBox";
import { VerticalDrawer } from "./components/VerticalDrawler";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import { CasinoCard } from "./components/CasinoCard";
import { CasinoCard as CasinoCardGame } from "../casino/components/CasinoCard";
import { SliderCard } from "../sports/components/SliderCard";
import { SearchModal } from "../sports/components/SearchModal";
import { TotalSelect } from "./components/TotalSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { TableBodyData, recommendedItems } from "./data";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useDispatch, useSelector } from "react-redux";
import { getTournament, selectSportsList } from "../store/tournamentSlice";
import { getselectedSport, selectMenuList, setAffiliateId, setMenuList, toggleLive } from "../store/uiSlice";
import { useEffect } from "react";
import DashboardBanner from "./components/DashboardBanner";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import RightSidebar from "../../shared-components/RightSidebar";
import withReducer from "app/store/withReducer";
import reducer from "../store";
import i18next from "i18next";
import { useIsLive } from "src/app/hooks/useIsLive";
import {
  selectGameData,
} from "../casino/store/gameSlice";
import { BettingsBox } from "./components/BettingsBox";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CasinoIcon from "@mui/icons-material/Casino";
import { selectCurrentLanguageId, selectMenu } from "app/store/i18nSlice";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";
import { memo } from "react";
import { PopularGameContainer } from "./components/PopularGameContainer";
import { BoostOddsContainer } from "./components/BoostOddsContainer";
import { GamesContainer } from "./components/GamesContainer";
import { VerticalDrawerContainer } from "./components/VerticalDrawerContainer";

const StyledLeagueButton = styled("button")(({ selected, theme }) => ({
  boxShadow: "0px 0px 6px 0px #E0E0E080",
  border: "1px solid #E0E0E080",
  color: selected
    ? theme.palette.success.contrastText
    : theme.palette.text.primary,
  backgroundColor: selected
    ? theme.palette.success.main
    : theme.palette.background.default,
}));



export const LeagueButton = ({ label, icon, selected, onClick }) => {
  return (
    <StyledLeagueButton
      selected={selected}
      className="text-[16px] px-[8px] md:px-[16px] py-[4px] font-500 flex items-center gap-[8px] rounded-[4px]"
      onClick={onClick}
    >
      <Typography className="whitespace-nowrap text-[inherit]">
        {label}
      </Typography>
      <img
        src={icon}
        alt="icon"
        className="w-[18px] h-[18px] min-w-[18px] min-h-[18px] object-cover sport-item-icon"
      />
    </StyledLeagueButton>
  );
};

const StyledCategoryButton = styled(Box)(
  ({ bgcolorvalue, bordercolorvalue, selected }) => ({
    backgroundColor: bgcolorvalue,
    border: selected ? `1px solid ${bordercolorvalue}` : undefined,
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: "8px",
    paddingBlock: "8px",
    gap: "5.4px",
    borderRadius: "8px",
    "& > span": {
      textTransform: "uppercase",
      fontFamily: "styrene",
      fontSize: "12px",
      color: "#505155",
    },
  })
);

const CategoryButton = (props) => {
  const { image, title, bgcolorvalue, bordercolorvalue, textcolor, selected } =
    props;
  const theme = useTheme();

  return (
    <StyledCategoryButton
      bgcolorvalue={bgcolorvalue}
      bordercolorvalue={bordercolorvalue}
      selected={selected}
      color={theme.palette.text.primary}
    >
      {/* <img src={image} alt="" /> */}
      {image}
      <Typography color={textcolor}>{title}</Typography>
    </StyledCategoryButton>
  );
};


const paths = {
  sports_betting: '/sports/top',
  home: '/home',
  live: '/sports/top?live=true',
  casino: '/casino/all',
  live_casino: '/live-casino/all',
  virtual_games: '/virtual-games/all'
}

function Dashboard() {
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();
  const isMd = useScreenMd();
  const dispatch = useDispatch();
  const { isLive } = useIsLive();
  const systemType = useSystemType();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const affiliateId = queryParams.get("afflink");
  const menus = useSelector(selectMenu)
  const menuList = useSelector(selectMenuList);
  const theme = useTheme();

  useEffect(() => {
    if (menus.length > 0) {
      const availableMenus = [...menus]
      const slugs = availableMenus.map((menu) => menu.slug);
      dispatch(setMenuList(slugs))
      const isAccessible = availableMenus.filter((menu) => menu.slug === 'home').length > 0;
      const firstMenu = menus[0];
      if (!isAccessible) navigate(paths[firstMenu.slug])
    }
  }, [menus])

  useEffect(() => {
    dispatch(setAffiliateId(affiliateId));
  }, [affiliateId]);

  useEffect(() => {
    dispatch(getTournament({ langId: currentLanguageId, type: "unplayed" }));
  }, [currentLanguageId]);

  const handleSearchClick = () => {
    setSearchVisible(true);
  };

  useEffect(() => {
    if (isLive) {
      dispatch(toggleLive());
    }
    dispatch(
      getTournament({ langId: "en", type: isLive ? "Live" : "unplayed" })
    );
  }, [dispatch, isLive]);

  console.log(menuList, 'menuList')

  return (
    <>
      <SearchModal
        visible={searchVisible}
        onClose={() => setSearchVisible(false)}
      />

      <Box
        aria-label="dashboard"
        className="flex gap-[12px] md:pl-0 md:pr-[12px]"
      >
        <Box aria-label="leftSideBar" className="min-w-[244px] hidden md:block">
          <Box className="flex flex-col gap-[18px]">
            <Box
              className="pt-[16px] pb-[12px] px-[10px] rounded-br-[12px]"
              bgcolor={theme.palette.background.paper}
            >
              <Box className="flex gap-[9px] justify-center">
                {(menuList.includes('sports_betting') || menuList.includes('live')) && (
                  <CategoryButton
                    bgcolorvalue={theme.palette.success.main}
                    // bordercolorvalue={theme.palette.secondary.dark}
                    textcolor={theme.palette.success.contrastText}
                    selected={true}
                    // image="assets/images/navItems/sports.svg"
                    image={
                      <SportsSoccerIcon
                        fontSize="small"
                        htmlColor={theme.palette.success.contrastText}
                      />
                    }
                    title={i18next.t("Sports")}
                  />
                )}
                {(menuList.includes('casino') || menuList.includes('live_casino') || menuList.includes('virtual_games')) && (
                  <CategoryButton
                    bgcolorvalue={theme.palette.warning.main}
                    // bordercolorvalue="#38AD9C"
                    textcolor={theme.palette.warning.contrastText}
                    selected={false}
                    // image="assets/images/navItems/live-casino.svg"
                    image={
                      <CasinoIcon
                        fontSize="small"
                        htmlColor={theme.palette.warning.contrastText}
                      />
                    }
                    title={i18next.t("Casino")}
                  />
                )}
              </Box>
              <Box className="mt-[10px]">
                <SearchBox boxProps={{ onClick: handleSearchClick }} />
              </Box>
            </Box>
            <VerticalDrawerContainer />
          </Box>
        </Box>

        <Box
          aria-label="mainContent"
          className="flex flex-col gap-[15px] max-h-[calc(var(--app-height)-134px)] overflow-y-auto md:pr-[8px] w-full justify-between"
        >
          <Box className="mt-[16px] px-[4px]">
            <DashboardBanner category="home" />
          </Box>
          {menuList.includes('casino') && (
            <PopularGameContainer />
          )}
          {(menuList.includes('sports_betting') || menuList.includes('live')) && (
            <BoostOddsContainer />
          )}
          {menuList.includes('live') && (
            <Box className="px-[4px]">
              <BettingsBox
                title={i18next.t("Live_Bettings")}
                betType="Live"
                onMore={() => navigate("/sports/top?live=true")}
              />
            </Box>
          )}
          {menuList.includes('sports_betting') && (
            <Box className="px-[4px]">
              <BettingsBox
                title={i18next.t("Sports_Betting")}
                betType="unplayed"
                onMore={() => navigate("/sports/top")}
              />
            </Box>
          )}

          {(systemType !== BETSHOP_SYSTEM && menuList.includes('live_casino')) && (
            <GamesContainer />
          )}

          <FooterLayout1 />
        </Box>

        {(isMd && (menuList.includes('sports_betting') || menuList.includes('live') || menuList.includes('casino') || menuList.includes('live_casino'))) &&
          <RightSidebar />}
      </Box>
    </>
  );
}

export default withReducer("sportsApp", reducer)(memo(Dashboard));
