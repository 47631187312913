import { Box } from "@mui/material";
import { WhiteBoard } from "./WhiteBoard";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import { CasinoCard as CasinoCardGame } from "../../casino/components/CasinoCard";
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import i18next from "i18next";
import { useState } from "react";
import { useEffect } from "react";
import { getPopularGames } from "../../casino/store/gameSlice";
import { memo } from "react";
import { useScreenMd } from "src/app/hooks/useScreens";

export const PopularGameContainer = memo(() => {
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [popularGames, setPopularGames] = useState([]);
  const isMd = useScreenMd();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPopularGames()).then((res) => {
      const gamesFiltered = res.payload.filter(
        (item) => (item.mobile && !isMd) || (!item.mobile && isMd)
      );
      setPopularGames(gamesFiltered);
    });
  }, [])

  return (
    <Box className="px-[4px]">
      <WhiteBoard title={i18next.t("Recommended_For_You")}>
        <Box className="max-w-full">
          <HorizontalScroll>
            <Box className="flex gap-[12px] justify-start">
              {popularGames.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className="w-full aspect-square min-w-[180px] min-h-[180px] max-w-[200px] max-h-[200px]"
                  >
                    <CasinoCardGame
                      key={index}
                      rootPath="Live Casino"
                      origin="game"
                      item={item}
                    />
                  </Box>
                );
              })}
            </Box>
          </HorizontalScroll>
        </Box>
      </WhiteBoard>
    </Box>
  )
})