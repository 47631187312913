import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;
const datafeed_domain = jwtServiceConfig.datafeedUrl;

export const getFavorites = createAsyncThunk("sportsApp/getFavorites", async (data) => {
  const { langId } = data;
  const res = await axios.get(`${api_domain}favourites/${langId}`);
  const {
    events: { sportEventsList },
    tournaments,
    games,
  } = res.data;
  return { events: sportEventsList ?? [], leagues: tournaments, games };
});


export const removeFavoriteLeague = createAsyncThunk("sportsApp/removeFavoriteLeague", async (id) => {
  await axios.get(`${api_domain}favourites/remove/tournament/${id}`);
  return { success: true, id };
});

export const removeFavoriteEvent = createAsyncThunk("sportsApp/removeFavoriteEvent", async (id) => {
  const res = await axios.get(`${api_domain}favourites/remove/event/${id}`);
  return { success: true, id };
});

export const addFavoriteEvent = createAsyncThunk("sportsApp/addFavoriteEvent", async (event) => {
  const res = await axios.get(`${api_domain}favourites/add/event/${event.sportEvent.id}`);
  return { success: true, event };
});

export const addFavoriteLeague = createAsyncThunk("sportsApp/addFavoriteLeague", async (league) => {
  const res = await axios.get(`${api_domain}favourites/add/tournament/${league.id}`);
  return { success: true, league };
});

export const getFavoriteEventsForLeagues = createAsyncThunk(
  "sportsApp/getFavoriteEventsForLeagues",
  async ({ favLeagues, leagueList, betType }) => {
    const events = [];
    for await (const league of favLeagues) {
      const leagueData = leagueList.find((lea) => lea.id === league.id);
      if (!leagueData) continue;
      const { id: tournament_id, country_id, sport_id } = leagueData;
      // const res = await axios.get(`${datafeed_domain}events/${betType}/${sport_id}/${country_id}/${tournament_id}`);
      const res = await axios.get(`${api_domain}events/${betType}/${sport_id}/${country_id}/${tournament_id}`);
      const { sportEventList } = res.data;
      events.push(...sportEventList.map((e) => ({ ...e, sportEvent: { ...e.sportEvent, tournament_id, country_id } })));
    }
    return events;
  }
);

export const addFavoriteGames = createAsyncThunk("sportsApp/getFavoriteGames", async (gameId) => {
  await axios.get(`${api_domain}favourites/add/game/${gameId}`);
  return { success: true, gameId };
});

export const removeFavoriteGames = createAsyncThunk("sportsApp/getFavoriteGames", async (gameId) => {
  await axios.get(`${api_domain}favourites/remove/game/${gameId}`);
  return { success: true, gameId };
});

const favAdapter = createEntityAdapter();

export const { selectAll: selectAllFavs } = favAdapter.getSelectors((state) => state.sportsApp.tickets);

const favSlice = createSlice({
  name: "sportsApp/favs",
  initialState: favAdapter.getInitialState({
    favLeagues: [],
    favEvents: [],
    favMarkets: [],
    favOdds: [],
    favEventsForLeagues: [],
    favGames: [],
    status: "idle",
    addingLeagueStatus: "idle",
    addingEventStatus: "idle",
    removingLeagueStatus: "idle",
    removingEventStatus: "idle",
    gettingEventsForLeaguesStatus: "idle",
    addGameStatus: "idle",
  }),
  reducers: {
    addFavLeague: {
      reducer: (state, action) => {
        state.favLeagues.push(action.payload);
      },
    },
    removeFavLeague: {
      reducer: (state, action) => {
        state.favLeagues = state.favLeagues.filter((league) => league.id !== action.payload);
      },
    },
    addFavEvent: {
      reducer: (state, action) => {
        state.favEvents.push(action.payload);
      },
    },
    removeFavEvent: {
      reducer: (state, action) => {
        state.favEvents = state.favEvents.filter((event) => event.sportEvent.id !== action.payload);
      },
    },
    addFavMarket: {
      reducer: (state, action) => {
        state.favMarkets.push(action.payload);
      },
    },
    removeFavMarket: {
      reducer: (state, action) => {
        state.favMarkets = state.favMarkets.filter((market) => market.id !== action.payload);
      },
    },
    toggleFavOdds: (state, action) => {
      const { eventId, type } = action.payload;
      const exist = state.favOdds.find((odd) => odd.eventId === eventId && odd.type === type);
      return {
        ...state,
        favOdds: exist
          ? state.favOdds.filter((odd) => !(odd.eventId === eventId && odd.type === type))
          : [...state.favOdds, action.payload],
      };
    },
    addFavGameSync: (state, action) => {
      state.favGames = [...state.favGames, action.payload];
    },
    removeFavGameSync: (state, action) => {
      state.favGames = state.favGames.filter((g) => g.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const { events, leagues, games } = action.payload;
        state.favEvents = events;
        state.favLeagues = leagues;
        state.favGames = games;
      })
      .addCase(getFavorites.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(addFavoriteEvent.pending, (state) => {
        state.addingEventStatus = "loading";
      })
      .addCase(addFavoriteEvent.fulfilled, (state, action) => {
        state.addingEventStatus = "fulfilled";
        const { event } = action.payload;
        state.favEvents = [...state.favEvents, event];
      })
      .addCase(addFavoriteEvent.rejected, (state) => {
        state.addingEventStatus = "rejected";
      })
      .addCase(addFavoriteLeague.pending, (state) => {
        state.addingLeagueStatus = "loading";
      })
      .addCase(addFavoriteLeague.fulfilled, (state, action) => {
        state.addingLeagueStatus = "fulfilled";
        const { league } = action.payload;
        state.favLeagues = [...state.favLeagues, league];
      })
      .addCase(addFavoriteLeague.rejected, (state) => {
        state.addingLeagueStatus = "rejected";
      })
      .addCase(removeFavoriteLeague.pending, (state) => {
        state.removingLeagueStatus = "loading";
      })
      .addCase(removeFavoriteLeague.fulfilled, (state, action) => {
        state.removingEventStatus = "fulfilled";
        const { id } = action.payload;
        state.favLeagues = state.favLeagues.filter((lea) => lea.id !== id);
      })
      .addCase(removeFavoriteLeague.rejected, (state) => {
        state.removingLeagueStatus = "rejected";
      })
      .addCase(removeFavoriteEvent.pending, (state) => {
        state.removingEventStatus = "loading";
      })
      .addCase(removeFavoriteEvent.fulfilled, (state, action) => {
        state.removingEventStatus = "fulfilled";
        const { id } = action.payload;
        state.favEvents = state.favEvents.filter((e) => e.sportEvent.id !== id);
      })
      .addCase(removeFavoriteEvent.rejected, (state) => {
        state.removingEventStatus = "rejected";
      })
      .addCase(getFavoriteEventsForLeagues.pending, (state) => {
        state.gettingEventsForLeaguesStatus = "loading";
      })
      .addCase(getFavoriteEventsForLeagues.fulfilled, (state, action) => {
        state.gettingEventsForLeaguesStatus = "fulfilled";
        state.favEventsForLeagues = action.payload;
      })
      .addCase(getFavoriteEventsForLeagues.rejected, (state) => {
        state.gettingEventsForLeaguesStatus = "rejected";
      })

      .addCase(addFavoriteGames.pending, (state) => {
        state.addGameStatus = "loading";
      })
      .addCase(addFavoriteGames.fulfilled, (state, action) => {
        state.addGameStatus = "fulfilled";
      })
      .addCase(addFavoriteGames.rejected, (state) => {
        state.addGameStatus = "rejected";
      });
  },
});

export const {
  addFavLeague,
  removeFavLeague,
  addFavEvent,
  removeFavEvent,
  addFavMarket,
  removeFavMarket,
  toggleFavOdds,
  removeFavGameSync,
  addFavGameSync,
} = favSlice.actions;

export const selectFavLeagues = ({ sportsApp }) => sportsApp.favs.favLeagues;
export const selectFavEvents = ({ sportsApp }) => sportsApp.favs.favEvents;
export const selectFavMarkets = ({ sportsApp }) => sportsApp.favs.favMarkets;
export const selectFavOdds = ({ sportsApp }) => sportsApp.favs.favOdds;
export const selectFavStatus = ({ sportsApp }) => sportsApp.favs.status;
export const selectFavEventsForLeagues = ({ sportsApp }) => sportsApp.favs.favEventsForLeagues;
export const selectFavGames = ({ sportsApp }) => sportsApp.favs.favGames;

export default favSlice.reducer;
