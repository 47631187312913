import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";

const api_domain = jwtServiceConfig.apiUrl;

export const claimBonus = createAsyncThunk("settingApp/claimBonus", async ({bonusType}) => {
  try {
    const response = await axios.get(`${api_domain}bonus/claim/cashback/${bonusType}`);
    toast.custom(
      <Alert severity="success">Success</Alert>
    );
    return response;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || `Failed to ${bonusType} claim bonus`}</Alert>
    );
    throw err;
  }
});

export const getBetBonus = createAsyncThunk("settingApp/getBetBonus", async ({bonusType, status}) => {
  try {
    const response = await axios.get(`${api_domain}bonus/offer/list/${bonusType}/${status}`);
    return response.data.user_bonus_list;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch coupons history"}</Alert>
    );
    throw err;
  }
});

const bonusAdapter = createEntityAdapter([]);

const bonusSlice = createSlice({
  name: "settingApp/bonus",
  initialState: bonusAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBetBonus.fulfilled, (state, action) => {
      bonusAdapter.setAll(state, action.payload);
    });
  },
});

export const { selectAll: selectBetBonus } = bonusAdapter.getSelectors((state) => state.settingApp.bonus);

export default bonusSlice.reducer;
