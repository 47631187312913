import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Popover, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { selectGameData } from "../main/casino/store/gameSlice";
// import { getGameListByVendor, getVendors, selectVendors } from "../main/casino/store/gameSlice";
// import { setCasinoGame } from "../main/store/uiSlice";
import { vendorPath } from 'src/app/utils';

export const Select = (props) => {
  const { typeId, rootPath, handleItemClick, title } = props;
  const dispatch = useDispatch();
  const { vendors } = useSelector(selectGameData);
  const [menu, setMenu] = useState(null);
  const navigator = useNavigate();
  const { vendorId } = useParams();

  // useEffect(() => {
  //   if (typeId && casinoId !== "all") {
  //     // dispatch(getVendors(typeId));
  //   } else if (typeId === "all") {
  //     // dispatch(getAllVendors(casinoTypes.map((c) => c.id)));
  //   }
  // }, [typeId, dispatch]);

  const handleProviderChange = (provider) => {
    // setGame({ id: "all", name: "All Games" });
    // setProvider(provider.vendor_id);
    // dispatch(getGameListByVendor({ vendorId: provider.vendor_id }));
    navigator(`/${rootPath}/vendor/${provider.vendor_id}`);
  };

  // const setGame = (game) => {
  //   dispatch(setCasinoGame(game));
  // };

  const onMenuClose = () => {
    setMenu(null);
  };

  return (
    <Box className="relative flex justify-end">
      <Box
        aria-label="searchBox"
        className="border border-[#EDF0F2] rounded-[12px] py-[7px] pl-[10px] pr-[14px] flex items-center justify-between w-full md:w-[200px]"
        onClick={(e) => setMenu(e.currentTarget)}
      >
        <Typography className="text-[12px] text-[#3E4049] whitespace-nowrap leading-none">
          {title || "All Vendors"}
        </Typography>
        <KeyboardArrowDownIcon className="text-white" fontSize="small" />
      </Box>
      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: "p-12 max-h-[400px] !duration-75 bg-[white] text-[#6f7b8d] mt-[5px] w-[800px] relative",
        }}
      >
        <Box className="grid grid-cols-2 md:grid-cols-4 gap-[8px] relative">
          
          {vendors.map((p) => (
            <div >
              <button
                key={p.id}
                className="flex items-center text-[14px] text-[#6f7b8d] hover:text-white whitespace-nowrap leading-none p-[12px] hover:bg-[#4da533] w-full text-left rounded-full capitalize data-[selected]:bg-[#4da533] data-[selected]:text-white"
                data-selected={p.vendor_id.toString() === vendorId || null}
                onClick={() => {
                  onMenuClose();
                  handleProviderChange(p);
                }}
              >
                {p.vendor_name}
              </button>
            </div>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
