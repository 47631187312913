import { Box, Stack } from '@mui/material';
import React from 'react';
import { memo } from 'react';
import { WhiteBoard } from './WhiteBoard';
import { HorizontalScroll } from 'app/shared-components/HorizontalScroll';
import { CasinoCard as CasinoCardGame } from "../../casino/components/CasinoCard";
import { CustomTable } from 'app/shared-components/CustomTable';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCasinoHighlight, getGamesForCasinoGameType } from '../../casino/store/gameSlice';
import i18next from 'i18next';
import { useScreenMd } from 'src/app/hooks/useScreens';
import { dateToText, formatText } from 'src/app/utils';
import { useNavigate } from 'react-router-dom';
import { selectCurrentLanguageId } from 'app/store/i18nSlice';

const headRows = [
  {
    id: "date",
    align: "left",
    disablePadding: false,
    label: i18next.t("Date"),
    sort: true,
    value: (row) => dateToText(row.date),
  },
  {
    id: "game",
    align: "left",
    disablePadding: false,
    label: i18next.t("Game"),
  },
  {
    id: "user",
    align: "left",
    disablePadding: false,
    label: i18next.t("User"),
    sort: true,
    value: (row) => formatText(row.user),
  },
  {
    id: "total_won",
    align: "left",
    disablePadding: false,
    label: i18next.t("Winnigs"),
    sort: true,
    value: (row) => `${row.total_won.toFixed(2)} ${row.currency}`,
  },
];

export const GamesContainer = memo(() => {
  const [liveGames, setLiveGames] = useState([]);
  const [casinoGameOverview, setCasinoGameOverview] = useState([]);
  const dispatch = useDispatch()
  const isMd = useScreenMd();
  const navigate = useNavigate()
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getGamesForCasinoGameType(5)).then((res) => {
      const gamesFiltered = res.payload.filter(
        (item) => (item.mobile && !isMd) || (!item.mobile && isMd)
      );
      setLiveGames(gamesFiltered);
    });
    dispatch(getCasinoHighlight()).then((res) => {
      setCasinoGameOverview(res.payload);
    });
  }, [])

  return (
    <Stack gap="16px">
      <Box className="px-[4px]">
        <WhiteBoard 
          title={i18next.t("Games")}
          handleMore={() => navigate("/live-casino/all")}
        >
          <Box className="max-w-full">
            <HorizontalScroll>
              <Box className="flex gap-[12px] justify-start">
                {liveGames.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      className="w-full aspect-square min-w-[180px] min-h-[180px] max-w-[200px] max-h-[200px]"
                    >
                      <CasinoCardGame
                        key={index}
                        rootPath="Live Casino"
                        origin="game"
                        item={item}
                      />
                    </Box>
                  );
                })}
              </Box>
            </HorizontalScroll>
          </Box>
        </WhiteBoard>
      </Box>

      <Box className="max-w-[100vw] px-[4px]">
        <Box className="max-w-full overflow-auto">
          <Box
            className="max-w-full overflow-auto"
            style={{ maxHeight: "500px" }}
          >
            <WhiteBoard title={i18next.t("Latest_Winner")} moreButtonHidden>
              <Box className="max-w-full">
                <CustomTable
                  rows={casinoGameOverview}
                  columns={headRows}
                  noPagination={true}
                />
              </Box>
            </WhiteBoard>
          </Box>
        </Box>
      </Box>
    </Stack>
  )
})