import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { useScreenMd } from "src/app/hooks/useScreens"
import { selectGameData } from "../store/gameSlice";
import { useMemo } from "react";
import { CasinoCard } from "./CasinoCard";
import { useEffect } from "react";
import { useState } from "react";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import i18n from "src/i18n";

export const CasinoGroup = ({ title, items: itemsProp, onAllClick, rootPath, searchValue }) => {
  const isMd = useScreenMd();
  const { vendorsStatus, gamesStatus } = useSelector(selectGameData);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const fulfilled = vendorsStatus === "fulfilled" && gamesStatus === "fulfilled";

  useEffect(() => {
    setLoading(true);
    const items = itemsProp.filter(item => {
      if (searchValue) {
        const matchesSearch = item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                              item.id_hash.toLowerCase().includes(searchValue.toLowerCase());
        if (!matchesSearch) return false;
      }
      return (item.mobile && !isMd) || (!item.mobile && isMd)}).slice(0, 16);

    setItems(items);
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [itemsProp, isMd, searchValue])
  
  if (items.length == 0) return <div/>

  return loading ? (
    <Box className="bg-white rounded-lg shadow-5 min-h-[400px] relative">
      <Box
        className="custom-loader absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
      />
    </Box>
  ) : (
    <Box className="bg-white flex flex-col rounded-[8px] shadow-5 w-full" aria-label="CasinoGroup">
      <Box className="flex justify-between items-center px-[24px] py-[12px] bg-[#E3E9ED] rounded-t-[8px]">
        <Typography className="text-[18px] sm:text-[20px] text-[#3E4049] font-bold capitalize">{title.replace(/-/g, " ")}</Typography>
        <button
          className="text-[#ec622b] uppercase p-[4px] rounded-md font-500 text-[12px] hover:bg-[#eee] active:bg-[#ddd]"
          onClick={onAllClick}
        // disabled={!fulfilled}
        >
          {i18n.t('SHOW_ALL')}
        </button>
      </Box>
      <Box className="mt-[10px] px-[24px] py-[12px] max-w-full overflow-x-hidden">
        <Box className="grid grid-cols-2 md:grid-cols-none md:grid-rows-2 md:grid-flow-col md:h-[420px] gap-[8px] justify-start">
          <Box className="w-full md:w-auto aspect-square md:row-span-2 md:h-full">
            <Box className="w-[100%] aspect-square md:w-[420px] md:h-[420px]">
              {items.length > 0 && <CasinoCard rootPath={rootPath} item={items[0]} />}
            </Box>
          </Box>
          {items.length > 1 && items.slice(1).map((item) => (
            <Box key={item.id} className="w-full md:w-auto aspect-square md:row-span-1 md:h-full">
              <Box className="w-[100%] aspect-square md:w-[210px] md:h-[210px]">
              {items.length > 0 && <CasinoCard rootPath={rootPath} item={item} />}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}