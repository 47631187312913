export const TableBodyData = [
  {
    column1: "Zeus the Thunderer",
    column2: "player413188",
    column3: "2:27 PM",
    column4: "0.2",
    column5: "x2.00",
    column6: "0.4",
  },
  {
    column1: "Zeus the Thunderer",
    column2: "player413188",
    column3: "2:27 PM",
    column4: "0.2",
    column5: "x2.00",
    column6: "0.4",
  },
  {
    column1: "Zeus the Thunderer",
    column2: "player413188",
    column3: "2:27 PM",
    column4: "0.2",
    column5: "x2.00",
    column6: "0.4",
  },
  {
    column1: "Zeus the Thunderer",
    column2: "player413188",
    column3: "2:27 PM",
    column4: "0.2",
    column5: "x2.00",
    column6: "0.4",
  },
];

export const recommendedItems = [
  {
    title: "3 Hot Chillies",
    subtitle: "3 Oaks",
    people: 317,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Gates of Olympus 1000",
    subtitle: "Pragmatic Play",
    people: 593,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "book of Tribes",
    subtitle: "Spinomenal",
    people: 122,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Lady Wolf Moon",
    subtitle: "BGamining",
    people: 161,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Penny Pelican",
    subtitle: "BGaminng",
    people: 159,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "20 Boost Hot",
    subtitle: "Felix Gaming",
    people: 181,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Lucky Crew",
    subtitle: "BGaming",
    people: 125,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    people: 245,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "20 Boost Hot",
    subtitle: "Felix Gaming",
    people: 181,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Lucky Crew",
    subtitle: "BGaming",
    people: 125,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    people: 245,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "20 Boost Hot",
    subtitle: "Felix Gaming",
    people: 181,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Lucky Crew",
    subtitle: "BGaming",
    people: 125,
    image: "assets/images/Casino/one.png",
  },
  {
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    people: 245,
    image: "assets/images/Casino/one.png",
  },
];

export const popularItems = [
  { name: "LaLiga", icon: "assets/images/pages/dashboard/laliga.svg" },
  {
    name: "Premier League",
    icon: "assets/images/pages/dashboard/premier.svg",
  },
  { name: "Serie A", icon: "assets/images/pages/dashboard/serie-a.svg" },
];

export const sportsItems = [
  {
    id: 0,
    title: "Football",
    icon: "assets/images/sports-svg/soccer.svg",
    path: "/sports/football",
  },
  {
    id: 1,
    title: "Basketball",
    icon: "assets/images/sports-svg/basketball.svg",
    path: "/sports/basketball",
  },
  {
    id: 2,
    title: "Tennis",
    icon: "assets/images/sports-svg/tennis.svg",
    path: "/sports/tennis",
  },
  {
    id: 3,
    title: "Volleyball",
    icon: "assets/images/sports-svg/volleyball.svg",
    path: "/sports/volleyball",
  },
  {
    id: 4,
    title: "Beach Volleyball",
    icon: "assets/images/sports-svg/volleyball.svg",
    path: "/sports/beach-volleyball",
  },
  {
    id: 5,
    title: "Table Tennis",
    icon: "assets/images/sports-svg/table-tennis.svg",
    path: "/sports/table-tennis",
  },
  {
    id: 6,
    title: "Badminton",
    icon: "assets/images/sports-svg/badminton.svg",
    path: "/sports/badminton",
  },
  {
    id: 7,
    title: "American Football",
    icon: "assets/images/sports-svg/football.svg",
    path: "/sports/american-football",
  },
  {
    id: 8,
    title: "Golf",
    icon: "assets/images/sports-svg/golf.svg",
    path: "/sports/golf",
  },
  {
    id: 9,
    title: "Snooker",
    icon: "assets/images/sports-svg/snooker.svg",
    path: "/sports/snooker",
  },
  {
    id: 10,
    title: "Darts",
    icon: "assets/images/sports-svg/darts.svg",
    path: "/sports/darts",
  },
  {
    id: 11,
    title: "Cricket",
    icon: "assets/images/sports-svg/cricket.svg",
    path: "/sports/cricket",
  },
  {
    id: 12,
    title: "Pool Ball",
    icon: "assets/images/sports-svg/pool-ball.svg",
    path: "/sports/poolball",
  },
  {
    id: 13,
    title: "Squash Ball",
    icon: "assets/images/sports-svg/squash-ball.svg",
    path: "/sports/squash-ball",
  },
  {
    id: 14,
    title: "Hockey",
    icon: "assets/images/sports-svg/hockey.svg",
    path: "/sports/hockey",
  },
];