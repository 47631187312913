import SystemOptions from "src/app/main/sports/components/sidebar/SystemOptions";
import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Box,
  RadioGroup,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Radio,
  useTheme,
  CssBaseline,
} from "@mui/material";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBox } from "src/app/main/dashboard/components/CardBox";
import {
  selectTickets,
  setOddsSetting,
  selectOddsSetting,
  selectBankers,
  selectTicketStatus,
  initTickets,
  selectTotalOdds,
  selectPossibleWinnings,
  selectTotalBetAmount,
  selectPrintTickets,
} from "src/app/main/store/ticketSlice";
import {
  getCouponDetailByCode,
  selectBetType,
  setBadgePosition,
  setBetslipVisbile,
  setBetType,
} from "src/app/main/store/uiSlice";
import { TicketCard } from "./TicketCard";
import { SettingCard } from "./SettingCard";
import { TotalizeCard } from "./TotalizeCard";
import React, { useState } from "react";
import { useEffect } from "react";
import { useBoundingClientRect } from "src/app/hooks/useBoundingClientRect";
import { useScreenMd } from "src/app/hooks/useScreens";
import {
  ODD_SETTING_ACCEPT_ANY_ODDS,
  ODD_SETTING_ACCEPT_HIGHER_ODDS,
  ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
  PopularPrinters,
} from "src/app/constants";
import i18next from "i18next";
import SlipDetailDrawer from "src/app/main/settings/outlets/GameHistory/SlipDetailDrawer";
import { useMemo } from "react";
import { memo } from "react";
import { PrintRequestPopup } from "./PrintRequestPopup";
import { getUserPreference } from "src/app/main/settings/store/profileSlice";
import { useCustomAuth } from "src/app/hooks/useAuth";
import { selectMarketDic } from "src/app/main/store/prematchSlice";
import { PrintTickets } from "./PrintTickets";
import ReactDOM from 'react-dom';
import { StylesProvider } from "@mui/styles";

const betTypeButtons = [
  { id: "single", title: "Single", translate: "Single", minTicketCount: 1 },
  { id: "combo", title: "Combo", translate: "Combo", minTicketCount: 2 },
  { id: "system", title: "System", translate: "System", minTicketCount: 3 },
];

export const BetslipContainer = memo(({ closeSidebar }) => {
  const badgeRef = useRef(null);
  const betType = useSelector(selectBetType);
  const tickets = useSelector(selectTickets);
  const bankers = useSelector(selectBankers);
  const ticketStatus = useSelector(selectTicketStatus);
  const [editingAmountId, setEditingAmountId] = useState();
  const [settingVisible, setSettingVisible] = useState(false);
  const [combineErrMsg, setCombineErrMsg] = useState("");
  const [selectedBetType, setSelectedBetType] = useState('');
  const dispatch = useDispatch();
  const isMd = useScreenMd();
  const theme = useTheme();

  const onBadgeChange = () => {
    const badge = badgeRef.current;
    if (!badge) return;
    const { x, y } = badge.getBoundingClientRect();
    dispatch(setBadgePosition({ x, y }));
  };

  useBoundingClientRect(badgeRef, onBadgeChange);
  const [isCombineAvailable, setIsCombineAvailable] = useState(true);
  const [isCombineAvailableCombo, setIsCombineAvailableCombo] = useState(false);
  const ticketCount = tickets.length;
  const [couponCode, setCouponCode] = useState("");
  const [openSlipDetailDrawer, setOpenSlipDetailDrawer] = useState(false);
  const [couponUserDetails, setCouponUserDetails] = useState();
  const [printRequestPopupOpen, setPrintRequestPopupOpen] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(PopularPrinters[0]);
  const authenticated = useCustomAuth();
  const marketDic = useSelector(selectMarketDic);
  const totalBetAmount = useSelector(selectTotalBetAmount);
  const totalOdds = useSelector(selectTotalOdds)
  const potentialWin = useSelector(selectPossibleWinnings)
  const [autoPrint, setAutoPrint] = useState(false);
  const printTickets = useSelector(selectPrintTickets)

  useEffect(() => {
    if (authenticated) {
      dispatch(getUserPreference()).then((res) => {
        console.log(res.payload, 'payload')
        setAutoPrint(res.payload.auto_print);
        setSelectedPrinter(PopularPrinters.find(p => p.value === res.payload.printer_type) || PopularPrinters[0]);
      });
    }
  }, [authenticated])

  // Save tickets, bankers, etc., to the local storage
  useEffect(() => {
    if (ticketStatus === "idle") return;
    // localStorage.setItem('meganetdemo-odds', JSON.stringify({ tickets, bankers }));
  }, [tickets, bankers, ticketStatus]);

  // Load saved tickets, bankers from local storage
  useEffect(() => {
    if (ticketStatus === "idle") {
      // const data = localStorage.getItem("meganetdemo-odds");
      // if (data) {
      //   const { tickets, bankers } = JSON.parse(data);
      //   dispatch(initTickets({ tickets, bankers }));
      // } else {
      dispatch(initTickets({}));
      // }
    }
  }, [ticketStatus]);

  const handleCouponCode = (e) => {
    const { value } = e.target;
    setCouponCode(value);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(getCouponDetailByCode({ code: couponCode })).then((res) => {
        if (res.payload) {
          setCouponUserDetails(res.payload);
          setOpenSlipDetailDrawer(true);
        }
      });
    }, 500);
  }, [couponCode]);

  useEffect(() => {
    const eventReferenceCount = tickets.reduce((acc, ticket) => {
      acc[ticket.event_reference_id] =
        (acc[ticket.event_reference_id] || 0) + 1;
      return acc;
    }, {});
    const hasDuplicateEvents = Object.values(eventReferenceCount).some(
      (count) => count >= 2
    );

    let combileAvail = true;
    if (tickets.filter((t) => !t.active).length) {
      combileAvail = false;
      setCombineErrMsg("Selections contains locked odds");
    } else if (betType === "single") {
      combileAvail = true;
    } else if (hasDuplicateEvents) {
      combileAvail = false;
      setCombineErrMsg("Selections cannot be combined.");
    } else if (betType === "system" && tickets.length - bankers.length < 2) {
      combileAvail = false;
      setCombineErrMsg(
        "At least two of your picks may not be bankers if you would like to play a system with bankers."
      );
    }
    setIsCombineAvailable(combileAvail);
    setIsCombineAvailableCombo(!hasDuplicateEvents);
  }, [betType, tickets, bankers]);

  useEffect(() => {
    if (isCombineAvailableCombo && ticketCount === 2) {
      dispatch(setBetType("combo"));
    }
    if (!isCombineAvailable && ticketCount > 1) {
      dispatch(setBetType("single"));
    }
  }, [isCombineAvailableCombo, ticketCount]);

  useEffect(() => {
    if (ticketCount === 2 && betType === "system") {
      dispatch(setBetType("combo"));
    } else if (ticketCount === 1 && betType !== "single") {
      dispatch(setBetType("single"));
    }
  }, [ticketCount]);

  const handlePrintButtonClick = () => {
    // Open a new window with larger dimensions to fit the print dialog fully
    const printWindow = window.open('', '_blank', 'width=800,height=600'); // Adjust the window size to fit print dialog fully
  
    // Write HTML and CSS for the thermal paper size in the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Tickets</title>
          <style>
            /* Thermal paper size setup */
            @page {
              size: 80mm auto; /* Use 58mm auto for narrower paper */
              margin: 0;
            }
            body {
              margin: 0;
              padding: 0;
              font-family: monospace, sans-serif;
              -webkit-print-color-adjust: exact;
            }
            #print-root {
              width: 100%;
              padding: 8px;
              box-sizing: border-box;
              font-size: 12px;
              line-height: 1.2;
            }
            h1, h2 {
              font-size: 14px;
              text-align: center;
              margin: 4px 0;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              font-size: 11px;
              padding: 4px;
              text-align: left;
              border-bottom: 1px solid #000;
            }
            th {
              font-weight: bold;
            }
            .summary {
              display: flex;
              justify-content: space-between;
            }
          </style>
        </head>
        <body>
          <div id="print-root" style="display: flex; justify-content: center;"></div>
        </body>
      </html>
    `);
    printWindow.document.close();
  
    // Render the PrintTickets component into the new print window
    ReactDOM.render(
      <PrintTickets
        tickets={printTickets}
        betType={betType}
        totalBetAmount={totalBetAmount}
        totalOdds={totalOdds}
        potentialWin={potentialWin}
        marketDic={marketDic}
      />,
      printWindow.document.getElementById('print-root')
    );
  
    // Trigger print after content loads
    printWindow.addEventListener('load', () => {
      printWindow.print();
    });
  };
  
  

  // const handlePrintButtonClick = () => {

  //   const printWindow = window.open('', '_blank', 'width=auto,height=auto');
  //   printWindow.document.write('<html><head><title>Print Tickets</title></head><body><div id="print-root" style="display:flex;justify-content:center;"></div></body></html>');
  //   printWindow.document.close();

  //   ReactDOM.render(
  //     <PrintTickets
  //       tickets={printTickets}
  //       betType={betType}
  //       totalBetAmount={totalBetAmount}
  //       totalOdds={totalOdds}
  //       potentialWin={potentialWin}
  //       selectedPrinter={selectedPrinter}
  //       marketDic={marketDic}
  //     />,
  //     printWindow.document.getElementById('print-root')
  //   );

  //   if (autoPrint) {
  //     printWindow.focus();
  //     printWindow.print();
  //   }
  // };

  const BetTypeTab = memo(({ betAvailable }) => {
    const theme = useTheme();
    const handleBetTapChange = (bet) => {
      dispatch(setBetType(bet));
      setSelectedBetType(bet);
    }



    return (
      <Box className="w-full flex justify-between border-b border-b-[#00000010]">
        {betTypeButtons.map((t, index) => (
          <button
            key={index}
            className="w-1/3 py-[8px] data-[selected=true]:bg-[#00000010] border-b-[2px] border-b-transparent data-[selected=true]:border-b-[green] disabled:opacity-50"
            style={{ color: theme.palette.text.primary }}
            data-selected={betType === t.id}
            onClick={() => {
              handleBetTapChange(t.id);
            }}
            disabled={
              t.minTicketCount > ticketCount ||
              (t.id !== "single" && !betAvailable)
            }
          >
            {i18next.t(t.translate)}
          </button>
        ))}
      </Box>
    );
  });

  const RadioButton = (props) => {
    const { checked, label } = props;
    return (
      <FormControlLabel
        value={label}
        control={
          <Radio
            sx={{
              "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                color: "yellowgreen",
              },
              "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                color: "yellowgreen",
              },
            }}
          />
        }
        label={label}
        sx={{
          "& .MuiTypography-root": { fontSize: "12px" },
          "& .MuiButtonBase-root": { py: "2px" },
        }}
      />
    );
  };

  const SettingPanel = (props) => {
    const { visible, onClose } = props;
    const selectedOddsSetting = useSelector(selectOddsSetting);
    const dispatch = useDispatch();

    const handleOddsSettingChange = (event) => {
      const newSetting = event.target.value;
      let settingValue;
      switch (newSetting) {
        case "Accept any odds":
          settingValue = ODD_SETTING_ACCEPT_ANY_ODDS;
          break;
        case "Accept higher odds":
          settingValue = ODD_SETTING_ACCEPT_HIGHER_ODDS;
          break;
        case "Don't accept odds change":
          settingValue = ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE;
          break;
        default:
          settingValue = ODD_SETTING_ACCEPT_ANY_ODDS;
      }
      dispatch(setOddsSetting(settingValue));
    };

    let defaultSetting;
    switch (selectedOddsSetting) {
      case ODD_SETTING_ACCEPT_ANY_ODDS:
        defaultSetting = "Accept any odds";
        break;
      case ODD_SETTING_ACCEPT_HIGHER_ODDS:
        defaultSetting = "Accept higher odds";
        break;
      case ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE:
        defaultSetting = "Don't accept odds change";
        break;
      default:
        defaultSetting = "Accept any odds";
    }

    return (
      <Box
        className="absolute left-0 bottom-0 z-[100] py-[8px] px-[16px] invisible data-[visible=true]:visible w-full border-b border-b-[#00000030]"
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        data-visible={visible}
        aria-label="SettingPanel"
      >
        <Box className="flex justify-between items-start">
          <Typography className="font-bold">
            {i18next.t("Betting_settings")}
          </Typography>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </Box>
        <Box className="text-[13px]">{i18next.t("Odds_Settings")}</Box>
        <Box className="mt-[8px]">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={defaultSetting}
            onChange={handleOddsSettingChange}
          >
            <RadioButton label={i18next.t("Accept_any_odds")} />
            <RadioButton label={i18next.t("Accept_higher_odds")} />
            <RadioButton label={i18next.t("Dont_accept_odds_change")} />
          </RadioGroup>
        </Box>
        <Box className="text-[11px] py-[12px]">
          {i18next.t('By_placing_a_bet,_you_automatically_accept_the_most_recent_version_of_the_Terms_and_Conditions')}
        </Box>
        <Divider className="border-[#00000030]" />
        <Box className="text-[13px] mt-[12px]">
          {i18next.t("Odds_Settings")}
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
            />
          }
          label="E-Mail Notification"
          sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
        />
      </Box>
    );
  };


  return (
    <CardBox
      header={
        <Box className="flex items-center h-full relative w-full">
          <Typography className="text-white text-[12px] uppercase font-500">
            {i18next.t("Betslip")}
          </Typography>
          <Box
            className="bg-[#f44336] rounded-full text-center min-w-[20px] h-[20px] scale-0 data-[visible]:scale-100 transition-all duration-150 ml-[12px]"
            data-visible={tickets.length !== 0 || null}
          >
            <Typography className="leading-snug">
              {/* {betType === "single" ? (tickets.length || "") : "!"} */}
              {tickets.length || ""}
            </Typography>
          </Box>
          <button
            onClick={
              isMd ? closeSidebar : () => dispatch(setBetslipVisbile(false))
            }
            className="absolute right-0 top-1/2 -translate-y-1/2"
          >
            <CloseIcon className="text-white" />
          </button>
        </Box>
      }
    >
      <SlipDetailDrawer
        open={openSlipDetailDrawer}
        handleClose={() => setOpenSlipDetailDrawer(false)}
        couponUserDetails={couponUserDetails}
      />
      <Box
        className="rounded-b-[12px] min-h-[calc(var(--app-height)-400px)] h-auto relative"
        bgcolor={theme.palette.background.paper}
      >
        <BetTypeTab betAvailable={isCombineAvailableCombo} />
        {tickets.length === 0 && (
          <Box className="flex flex-col justify-center gap-[20px] px-[24px]">
            <img
              src="/assets/images/ticket.png"
              alt=""
              width={100}
              h={100}
              className="w-auto h-[100px] object-contain opacity-50 mt-[40px]"
            />
            <Typography className="w-full text-center mt-[12px] text-[#868c96] text-[14px] font-bold">
              {i18next.t("Paste_a_booking_code_in_the_box_below")}
            </Typography>
            <Typography className="w-full text-center mt-[12px] text-[14px] text-[#868c96]">
              {i18next.t("or_choose_an_outcome_to_place_a_bet")}
            </Typography>
            {/* <Typography className="text-black w-full text-center mt-[50px] text-[16px] font-bold">
              {i18next.t("No_tickets_here")}
            </Typography>
            <Typography className="text-black w-full text-center">{i18next.t("Buy_a_ticket")}</Typography> */}
            <input
              className="border py-[12px] px-[24px] rounded-full text-[#343434]"
              value={couponCode}
              onChange={handleCouponCode}
            />
          </Box>
        )}
        {betType === "system" && tickets.length > 2 && (
          <SystemOptions />
        )}
        {tickets.length > 0 && (
          <>
            <Box
              className="relative data-[setting-visible=true]:min-h-[300px]"
              data-setting-visible={settingVisible}
            >
              {tickets.map((ticket) => {
                const cardId = ticket.id + ticket.detail;
                return (
                  <TicketCard
                    key={cardId}
                    ticket={ticket}
                    amountEditing={editingAmountId === cardId}
                    onAmountEdit={() => setEditingAmountId(cardId)}
                    onAmountOkay={() => setEditingAmountId(null)}
                  />
                );
              })}
              {!isCombineAvailable ? (
                <Typography className="text-red w-full text-center">
                  {combineErrMsg}
                </Typography>
              ) : null}
              <SettingCard
                panelVisible={settingVisible}
                onSettingClick={() => setSettingVisible(true)}
                onPanelClose={() => setSettingVisible(false)}
              />
              <SettingPanel
                visible={settingVisible}
                onClose={() => setSettingVisible(false)}
              />
              <Box
                className="absolute z-[99] left-0 top-0 w-full h-[calc(100%-5px)] bg-[#000000a0] invisible data-[visible=true]:visible"
                data-visible={settingVisible}
              ></Box>
            </Box>
            <TotalizeCard
              amountEditing={editingAmountId === "total-card"}
              onAmountEdit={() => setEditingAmountId("total-card")}
              onAmountOkay={() => setEditingAmountId(null)}
              betAvailable={isCombineAvailable}
              handlePrintRequest={() => setPrintRequestPopupOpen(true)}
            />
          </>
        )}
        {(printRequestPopupOpen && autoPrint) && (
          <PrintRequestPopup
            open={autoPrint && printRequestPopupOpen}
            handleClose={() => setPrintRequestPopupOpen(false)}
            openPrintPopup={handlePrintButtonClick}
          />
        )}
      </Box>
    </CardBox>
  );
});
