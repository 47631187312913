import { selectCurrentLanguageId } from "app/store/i18nSlice";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCMSList } from "src/app/main/store/cmsSlice";
import i18n from "src/i18n";

const sections = [
  { title: "Company", value: "company" },
  { title: "Terms_and_Privacy", value: "terms_and_privacy" },
  { title: "Rules", value: "rules" },
  { title: "Responsible_Gambling", value: "responsible_gambling" },
  { title: "FAQ", value: "faq" },
]

function FooterMenu() {
  const [isActiveMenu, setIsActiveMenu] = useState();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const mobileMenuToggle = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  useEffect(() => {
    dispatch(getCMSList("pages_of_contents")).then((res) => {
      const list = res.payload || [];
      setData(list.filter(item => !item.isdeleted && item.active));
    });
  }, []);

  return (
    <>
      <div className="footer_menu_wrapper">
        {sections.map((section, index) => (
          <div key={index} className={isActiveMenu ? "footer_menu active" : "footer_menu"}>
            <a onClick={mobileMenuToggle} className="justify-center text-xl">
              {i18n.t(section.title)}
              <i className="fa-solid fa-angle-down"></i>
            </a>
            <ul>
              {data
                .filter(item => item.section === section.value)
                .map((item, subIndex) => (
                  <li key={item.id} className="flex justify-center text-sm">
                    <Link to={`/information/${item.section}/${item.id}`} className="text-sm opacity-70">{i18n.t(item.title)}</Link>
                  </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}

export default FooterMenu;
