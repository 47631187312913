import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addGameListFromVendors,
  getGameListFromVendors,
  getVendors,
  selectGameData,
  selectGameList,
  selectGameStatus,
  selectVendors,
  setGameList,
} from "../store/gameSlice";
import { useIsLive } from "src/app/hooks/useIsLive";
import { CasinoCard } from "./CasinoCard";
import { useState } from "react";
import { useMemo } from "react";
import { LoadingBox } from "app/shared-components/LoadingBox";
import { getFavorites, selectFavGames, selectFavStatus } from "../../store/favSlice";
import { useScreenMd } from "src/app/hooks/useScreens";

export const FavoriteBox = ({ rootPath, origin, currentPage }) => {
  const { casinoId, vendorId } = useParams();
  const dispatch = useDispatch();
  const { isLive } = useIsLive();
  const favStatus = useSelector(selectFavStatus);
  const { games, gamesStatus } = useSelector(selectGameData);
  const isMd = useScreenMd();

  const vendorsPerPage = 2;
  const gamesPerPage = 20;
  // const [currentPage, setCurrentPage] = useState(0);

  const favGames = useSelector(selectFavGames);
  const moreVisible = useMemo(() => currentPage * gamesPerPage < favGames.length, [favGames]);

  // useEffect(() => {
  //   if (casinoId && casinoId !== "all") {
  //     dispatch(getVendors(casinoId));
  //     dispatch(setGameList([]));
  //   } else if (casinoId === "all") {
  //     // dispatch(getAllVendors(casinoTypes.map((c) => c.id)));
  //   }
  // }, [casinoId, dispatch, isLive]);

  useEffect(() => {
    dispatch(getFavorites({ langId: "en" }));
  }, [origin, dispatch]);

  useEffect(() => {
    // setCurrentPage(0);
  }, [vendorId]);

  // useEffect(() => {
  //   if (vendors.length === 0) return;
  //   dispatch(
  //     addGameListFromVendors({
  //       vendors: vendors.filter(
  //         (_, index) => index >= currentPage * vendorsPerPage && index < (currentPage + 1) * vendorsPerPage
  //       ),
  //     })
  //   );
  // }, [vendors, currentPage, vendorsPerPage]);

  return (
    <Box>
      <Box className="flex flex-wrap p-[26px] gap-[12px]">
        {favGames
          .filter((item) => (item.mobile && !isMd) || (!item.mobile && isMd))
          .slice(0, (currentPage + 1) * gamesPerPage)
          .map((game, index) => (
            <Box key={index} className="w-[180px] h-[180px] min-w-[180px] min-h-[180px] mt-[5px] mx-[5px]">
              <CasinoCard {...game} isFavorite={true} item={game} />
            </Box>
          ))}
      </Box>
      {/* {favStatus === "loading" && <LoadingBox loading={gamesStatus === "loading"} />} */}
      {moreVisible && favStatus === "fulfilled" && (
        <Box className="flex justify-center py-[24px]">
          {/* <button
            className="px-[8px] py-[4px] rounded-[4px] bg-green text-white hover:bg-[darkgreen]"
            onClick={() => setCurrentPage((c) => c + 1)}
          >
            Load more games
          </button> */}
        </Box>
      )}
    </Box>
  );
};
