// source: service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.EventStatus', null, global);
goog.exportSymbol('proto.proto.ListPrematchRequest', null, global);
goog.exportSymbol('proto.proto.ListPrematchResponse', null, global);
goog.exportSymbol('proto.proto.LiveData', null, global);
goog.exportSymbol('proto.proto.LiveData.DataCase', null, global);
goog.exportSymbol('proto.proto.LiveData.LiveOddsData', null, global);
goog.exportSymbol('proto.proto.LiveData.LiveOddsData.OddsData', null, global);
goog.exportSymbol('proto.proto.LiveData.MatchResult', null, global);
goog.exportSymbol('proto.proto.LiveData.MatchUpdate', null, global);
goog.exportSymbol('proto.proto.LiveData.Settlement', null, global);
goog.exportSymbol('proto.proto.LiveOddsRequest', null, global);
goog.exportSymbol('proto.proto.LiveScoreData', null, global);
goog.exportSymbol('proto.proto.LiveScoreRequest', null, global);
goog.exportSymbol('proto.proto.Odds', null, global);
goog.exportSymbol('proto.proto.Prematch', null, global);
goog.exportSymbol('proto.proto.Score', null, global);
goog.exportSymbol('proto.proto.ScoreData', null, global);
goog.exportSymbol('proto.proto.SettlementStatus', null, global);
goog.exportSymbol('proto.proto.TeamInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPrematchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPrematchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPrematchRequest.displayName = 'proto.proto.ListPrematchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPrematchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPrematchResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPrematchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPrematchResponse.displayName = 'proto.proto.ListPrematchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Prematch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Prematch.repeatedFields_, null);
};
goog.inherits(proto.proto.Prematch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Prematch.displayName = 'proto.proto.Prematch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Odds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Odds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Odds.displayName = 'proto.proto.Odds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.TeamInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.TeamInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.TeamInfo.displayName = 'proto.proto.TeamInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveOddsRequest.displayName = 'proto.proto.LiveOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveScoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveScoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveScoreRequest.displayName = 'proto.proto.LiveScoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.proto.LiveData.oneofGroups_);
};
goog.inherits(proto.proto.LiveData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.displayName = 'proto.proto.LiveData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData.LiveOddsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.LiveData.LiveOddsData.repeatedFields_, null);
};
goog.inherits(proto.proto.LiveData.LiveOddsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.LiveOddsData.displayName = 'proto.proto.LiveData.LiveOddsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData.LiveOddsData.OddsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveData.LiveOddsData.OddsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.LiveOddsData.OddsData.displayName = 'proto.proto.LiveData.LiveOddsData.OddsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData.MatchUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveData.MatchUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.MatchUpdate.displayName = 'proto.proto.LiveData.MatchUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData.MatchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveData.MatchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.MatchResult.displayName = 'proto.proto.LiveData.MatchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveData.Settlement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveData.Settlement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveData.Settlement.displayName = 'proto.proto.LiveData.Settlement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LiveScoreData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LiveScoreData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LiveScoreData.displayName = 'proto.proto.LiveScoreData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScoreData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScoreData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScoreData.displayName = 'proto.proto.ScoreData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Score = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Score, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Score.displayName = 'proto.proto.Score';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPrematchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPrematchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPrematchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPrematchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPrematchRequest}
 */
proto.proto.ListPrematchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPrematchRequest;
  return proto.proto.ListPrematchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPrematchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPrematchRequest}
 */
proto.proto.ListPrematchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPrematchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPrematchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPrematchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPrematchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPrematchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPrematchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPrematchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPrematchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPrematchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.proto.Prematch.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPrematchResponse}
 */
proto.proto.ListPrematchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPrematchResponse;
  return proto.proto.ListPrematchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPrematchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPrematchResponse}
 */
proto.proto.ListPrematchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Prematch;
      reader.readMessage(value,proto.proto.Prematch.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPrematchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPrematchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPrematchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPrematchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Prematch.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Prematch data = 1;
 * @return {!Array<!proto.proto.Prematch>}
 */
proto.proto.ListPrematchResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.proto.Prematch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Prematch, 1));
};


/**
 * @param {!Array<!proto.proto.Prematch>} value
 * @return {!proto.proto.ListPrematchResponse} returns this
*/
proto.proto.ListPrematchResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Prematch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Prematch}
 */
proto.proto.ListPrematchResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Prematch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPrematchResponse} returns this
 */
proto.proto.ListPrematchResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Prematch.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Prematch.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Prematch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Prematch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Prematch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    homeTeam: jspb.Message.getFieldWithDefault(msg, 3, ""),
    awayTeam: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isPopular: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    tournament: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sport: jspb.Message.getFieldWithDefault(msg, 9, ""),
    league: jspb.Message.getFieldWithDefault(msg, 10, ""),
    homeTeamInfo: (f = msg.getHomeTeamInfo()) && proto.proto.TeamInfo.toObject(includeInstance, f),
    awayTeamInfo: (f = msg.getAwayTeamInfo()) && proto.proto.TeamInfo.toObject(includeInstance, f),
    statsperformId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.proto.Odds.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Prematch}
 */
proto.proto.Prematch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Prematch;
  return proto.proto.Prematch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Prematch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Prematch}
 */
proto.proto.Prematch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomeTeam(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwayTeam(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPopular(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournament(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSport(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeague(value);
      break;
    case 11:
      var value = new proto.proto.TeamInfo;
      reader.readMessage(value,proto.proto.TeamInfo.deserializeBinaryFromReader);
      msg.setHomeTeamInfo(value);
      break;
    case 12:
      var value = new proto.proto.TeamInfo;
      reader.readMessage(value,proto.proto.TeamInfo.deserializeBinaryFromReader);
      msg.setAwayTeamInfo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatsperformId(value);
      break;
    case 14:
      var value = new proto.proto.Odds;
      reader.readMessage(value,proto.proto.Odds.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Prematch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Prematch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Prematch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Prematch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHomeTeam();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAwayTeam();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsPopular();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTournament();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLeague();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHomeTeamInfo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.proto.TeamInfo.serializeBinaryToWriter
    );
  }
  f = message.getAwayTeamInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.proto.TeamInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatsperformId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.proto.Odds.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Prematch.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string start_date = 2;
 * @return {string}
 */
proto.proto.Prematch.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string home_team = 3;
 * @return {string}
 */
proto.proto.Prematch.prototype.getHomeTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setHomeTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string away_team = 4;
 * @return {string}
 */
proto.proto.Prematch.prototype.getAwayTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setAwayTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_live = 5;
 * @return {boolean}
 */
proto.proto.Prematch.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_popular = 6;
 * @return {boolean}
 */
proto.proto.Prematch.prototype.getIsPopular = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setIsPopular = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string tournament = 7;
 * @return {string}
 */
proto.proto.Prematch.prototype.getTournament = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setTournament = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.proto.Prematch.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sport = 9;
 * @return {string}
 */
proto.proto.Prematch.prototype.getSport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setSport = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string league = 10;
 * @return {string}
 */
proto.proto.Prematch.prototype.getLeague = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setLeague = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional TeamInfo home_team_info = 11;
 * @return {?proto.proto.TeamInfo}
 */
proto.proto.Prematch.prototype.getHomeTeamInfo = function() {
  return /** @type{?proto.proto.TeamInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.TeamInfo, 11));
};


/**
 * @param {?proto.proto.TeamInfo|undefined} value
 * @return {!proto.proto.Prematch} returns this
*/
proto.proto.Prematch.prototype.setHomeTeamInfo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.clearHomeTeamInfo = function() {
  return this.setHomeTeamInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Prematch.prototype.hasHomeTeamInfo = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TeamInfo away_team_info = 12;
 * @return {?proto.proto.TeamInfo}
 */
proto.proto.Prematch.prototype.getAwayTeamInfo = function() {
  return /** @type{?proto.proto.TeamInfo} */ (
    jspb.Message.getWrapperField(this, proto.proto.TeamInfo, 12));
};


/**
 * @param {?proto.proto.TeamInfo|undefined} value
 * @return {!proto.proto.Prematch} returns this
*/
proto.proto.Prematch.prototype.setAwayTeamInfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.clearAwayTeamInfo = function() {
  return this.setAwayTeamInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Prematch.prototype.hasAwayTeamInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string statsperform_id = 13;
 * @return {string}
 */
proto.proto.Prematch.prototype.getStatsperformId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.setStatsperformId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated Odds odds = 14;
 * @return {!Array<!proto.proto.Odds>}
 */
proto.proto.Prematch.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.proto.Odds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Odds, 14));
};


/**
 * @param {!Array<!proto.proto.Odds>} value
 * @return {!proto.proto.Prematch} returns this
*/
proto.proto.Prematch.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.proto.Odds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Odds}
 */
proto.proto.Prematch.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.proto.Odds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Prematch} returns this
 */
proto.proto.Prematch.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Odds.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Odds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Odds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Odds.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sportsBookName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    betPoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    isMain: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    marketName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    market: jspb.Message.getFieldWithDefault(msg, 10, ""),
    homeRotationNumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    awayRotationNumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    deepLinkUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    selection: jspb.Message.getFieldWithDefault(msg, 15, ""),
    normalizedSelection: jspb.Message.getFieldWithDefault(msg, 16, ""),
    selectionLine: jspb.Message.getFieldWithDefault(msg, 17, ""),
    selectionPoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Odds}
 */
proto.proto.Odds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Odds;
  return proto.proto.Odds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Odds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Odds}
 */
proto.proto.Odds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportsBookName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBetPoints(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMain(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarket(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHomeRotationNumber(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAwayRotationNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeepLinkUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelection(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNormalizedSelection(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionLine(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSelectionPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Odds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Odds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Odds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Odds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSportsBookName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBetPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getIsMain();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMarket();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHomeRotationNumber();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAwayRotationNumber();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDeepLinkUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSelection();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNormalizedSelection();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSelectionLine();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSelectionPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Odds.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sports_book_name = 2;
 * @return {string}
 */
proto.proto.Odds.prototype.getSportsBookName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setSportsBookName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.proto.Odds.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.proto.Odds.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double timestamp = 5;
 * @return {number}
 */
proto.proto.Odds.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double bet_points = 6;
 * @return {number}
 */
proto.proto.Odds.prototype.getBetPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setBetPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool is_main = 7;
 * @return {boolean}
 */
proto.proto.Odds.prototype.getIsMain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setIsMain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_live = 8;
 * @return {boolean}
 */
proto.proto.Odds.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string market_name = 9;
 * @return {string}
 */
proto.proto.Odds.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string market = 10;
 * @return {string}
 */
proto.proto.Odds.prototype.getMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double home_rotation_number = 11;
 * @return {number}
 */
proto.proto.Odds.prototype.getHomeRotationNumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setHomeRotationNumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double away_rotation_number = 12;
 * @return {number}
 */
proto.proto.Odds.prototype.getAwayRotationNumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setAwayRotationNumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string deep_link_url = 13;
 * @return {string}
 */
proto.proto.Odds.prototype.getDeepLinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setDeepLinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string player_id = 14;
 * @return {string}
 */
proto.proto.Odds.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string selection = 15;
 * @return {string}
 */
proto.proto.Odds.prototype.getSelection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setSelection = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string normalized_selection = 16;
 * @return {string}
 */
proto.proto.Odds.prototype.getNormalizedSelection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setNormalizedSelection = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string selection_line = 17;
 * @return {string}
 */
proto.proto.Odds.prototype.getSelectionLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setSelectionLine = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional double selection_points = 18;
 * @return {number}
 */
proto.proto.Odds.prototype.getSelectionPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Odds} returns this
 */
proto.proto.Odds.prototype.setSelectionPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.TeamInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.TeamInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.TeamInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TeamInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamCity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    teamMascot: jspb.Message.getFieldWithDefault(msg, 4, ""),
    teamAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sport: jspb.Message.getFieldWithDefault(msg, 6, ""),
    league: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.TeamInfo}
 */
proto.proto.TeamInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.TeamInfo;
  return proto.proto.TeamInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.TeamInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.TeamInfo}
 */
proto.proto.TeamInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamMascot(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamAbbreviation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSport(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeague(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.TeamInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.TeamInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.TeamInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.TeamInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTeamMascot();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTeamAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLeague();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string team_name = 2;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getTeamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setTeamName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string team_city = 3;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getTeamCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setTeamCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string team_mascot = 4;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getTeamMascot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setTeamMascot = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string team_abbreviation = 5;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getTeamAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setTeamAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sport = 6;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getSport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setSport = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string league = 7;
 * @return {string}
 */
proto.proto.TeamInfo.prototype.getLeague = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.TeamInfo} returns this
 */
proto.proto.TeamInfo.prototype.setLeague = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveOddsRequest}
 */
proto.proto.LiveOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveOddsRequest;
  return proto.proto.LiveOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveOddsRequest}
 */
proto.proto.LiveOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveScoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveScoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveScoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveScoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveScoreRequest}
 */
proto.proto.LiveScoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveScoreRequest;
  return proto.proto.LiveScoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveScoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveScoreRequest}
 */
proto.proto.LiveScoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveScoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveScoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveScoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveScoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.proto.LiveData.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.proto.LiveData.DataCase = {
  DATA_NOT_SET: 0,
  MATCH: 1,
  ODDS: 2,
  MATCH_RESULT: 3,
  SETTLEMENT: 4
};

/**
 * @return {proto.proto.LiveData.DataCase}
 */
proto.proto.LiveData.prototype.getDataCase = function() {
  return /** @type {proto.proto.LiveData.DataCase} */(jspb.Message.computeOneofCase(this, proto.proto.LiveData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.toObject = function(includeInstance, msg) {
  var f, obj = {
    match: (f = msg.getMatch()) && proto.proto.LiveData.MatchUpdate.toObject(includeInstance, f),
    odds: (f = msg.getOdds()) && proto.proto.LiveData.LiveOddsData.toObject(includeInstance, f),
    matchResult: (f = msg.getMatchResult()) && proto.proto.LiveData.MatchResult.toObject(includeInstance, f),
    settlement: (f = msg.getSettlement()) && proto.proto.LiveData.Settlement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData}
 */
proto.proto.LiveData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData;
  return proto.proto.LiveData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData}
 */
proto.proto.LiveData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.LiveData.MatchUpdate;
      reader.readMessage(value,proto.proto.LiveData.MatchUpdate.deserializeBinaryFromReader);
      msg.setMatch(value);
      break;
    case 2:
      var value = new proto.proto.LiveData.LiveOddsData;
      reader.readMessage(value,proto.proto.LiveData.LiveOddsData.deserializeBinaryFromReader);
      msg.setOdds(value);
      break;
    case 3:
      var value = new proto.proto.LiveData.MatchResult;
      reader.readMessage(value,proto.proto.LiveData.MatchResult.deserializeBinaryFromReader);
      msg.setMatchResult(value);
      break;
    case 4:
      var value = new proto.proto.LiveData.Settlement;
      reader.readMessage(value,proto.proto.LiveData.Settlement.deserializeBinaryFromReader);
      msg.setSettlement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.LiveData.MatchUpdate.serializeBinaryToWriter
    );
  }
  f = message.getOdds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.LiveData.LiveOddsData.serializeBinaryToWriter
    );
  }
  f = message.getMatchResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.LiveData.MatchResult.serializeBinaryToWriter
    );
  }
  f = message.getSettlement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.LiveData.Settlement.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.LiveData.LiveOddsData.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.LiveOddsData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.LiveOddsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData.LiveOddsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.LiveOddsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameInfo: msg.getGameInfo_asB64(),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tournamentId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.proto.LiveData.LiveOddsData.OddsData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData.LiveOddsData}
 */
proto.proto.LiveData.LiveOddsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData.LiveOddsData;
  return proto.proto.LiveData.LiveOddsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData.LiveOddsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData.LiveOddsData}
 */
proto.proto.LiveData.LiveOddsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 2:
      var value = /** @type {!proto.proto.EventStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGameInfo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTournamentId(value);
      break;
    case 7:
      var value = new proto.proto.LiveData.LiveOddsData.OddsData;
      reader.readMessage(value,proto.proto.LiveData.LiveOddsData.OddsData.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.LiveOddsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.LiveOddsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData.LiveOddsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.LiveOddsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTournamentId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.LiveData.LiveOddsData.OddsData.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.LiveOddsData.OddsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData.LiveOddsData.OddsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.LiveOddsData.OddsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odds: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    gameId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    groupId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    collectionInfoId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    marketId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    outcomeOrder: jspb.Message.getFieldWithDefault(msg, 9, 0),
    name: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData}
 */
proto.proto.LiveData.LiveOddsData.OddsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData.LiveOddsData.OddsData;
  return proto.proto.LiveData.LiveOddsData.OddsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData.LiveOddsData.OddsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData}
 */
proto.proto.LiveData.LiveOddsData.OddsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOdds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCollectionInfoId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeOrder(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.LiveOddsData.OddsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData.LiveOddsData.OddsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.LiveOddsData.OddsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdds();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCollectionInfoId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOutcomeOrder();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string reference_id = 1;
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double odds = 2;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getOdds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setOdds = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string game_id = 3;
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 group_id = 5;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 collection_info_id = 6;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getCollectionInfoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setCollectionInfoId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 market_id = 7;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 outcome_id = 8;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 outcome_order = 9;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getOutcomeOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setOutcomeOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.OddsData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventStatus status = 2;
 * @return {!proto.proto.EventStatus}
 */
proto.proto.LiveData.LiveOddsData.prototype.getStatus = function() {
  return /** @type {!proto.proto.EventStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.proto.EventStatus} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes game_info = 3;
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.prototype.getGameInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes game_info = 3;
 * This is a type-conversion wrapper around `getGameInfo()`
 * @return {string}
 */
proto.proto.LiveData.LiveOddsData.prototype.getGameInfo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGameInfo()));
};


/**
 * optional bytes game_info = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGameInfo()`
 * @return {!Uint8Array}
 */
proto.proto.LiveData.LiveOddsData.prototype.getGameInfo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGameInfo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setGameInfo = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.clearGameInfo = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.LiveOddsData.prototype.hasGameInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 group_id = 4;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 tournament_id = 6;
 * @return {number}
 */
proto.proto.LiveData.LiveOddsData.prototype.getTournamentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated OddsData odds = 7;
 * @return {!Array<!proto.proto.LiveData.LiveOddsData.OddsData>}
 */
proto.proto.LiveData.LiveOddsData.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.proto.LiveData.LiveOddsData.OddsData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.LiveData.LiveOddsData.OddsData, 7));
};


/**
 * @param {!Array<!proto.proto.LiveData.LiveOddsData.OddsData>} value
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
*/
proto.proto.LiveData.LiveOddsData.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.LiveData.LiveOddsData.OddsData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.LiveData.LiveOddsData.OddsData}
 */
proto.proto.LiveData.LiveOddsData.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.LiveData.LiveOddsData.OddsData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.LiveData.LiveOddsData} returns this
 */
proto.proto.LiveData.LiveOddsData.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.MatchUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.MatchUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData.MatchUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.MatchUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    matchDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countryId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportCountryId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tournamentId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    homeId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    awayId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    willBeLive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    betradarId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    hasStream: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    hasScout: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    priority: jspb.Message.getFieldWithDefault(msg, 14, 0),
    matchInfo: msg.getMatchInfo_asB64(),
    hash: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 17, 0),
    gamesCount: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData.MatchUpdate}
 */
proto.proto.LiveData.MatchUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData.MatchUpdate;
  return proto.proto.LiveData.MatchUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData.MatchUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData.MatchUpdate}
 */
proto.proto.LiveData.MatchUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMatchDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountryId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportCountryId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTournamentId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHomeId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAwayId(value);
      break;
    case 9:
      var value = /** @type {!proto.proto.EventStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillBeLive(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetradarId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStream(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasScout(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMatchInfo(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHash(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUpdate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGamesCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.MatchUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.MatchUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData.MatchUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.MatchUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMatchDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCountryId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportCountryId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTournamentId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHomeId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAwayId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getWillBeLive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getHasStream();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHasScout();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getHash();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getGamesCount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 match_date = 2;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getMatchDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setMatchDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 country_id = 4;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getCountryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setCountryId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_country_id = 5;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getSportCountryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setSportCountryId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 tournament_id = 6;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getTournamentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 home_id = 7;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getHomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setHomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 away_id = 8;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getAwayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setAwayId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional EventStatus status = 9;
 * @return {!proto.proto.EventStatus}
 */
proto.proto.LiveData.MatchUpdate.prototype.getStatus = function() {
  return /** @type {!proto.proto.EventStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.proto.EventStatus} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool will_be_live = 10;
 * @return {boolean}
 */
proto.proto.LiveData.MatchUpdate.prototype.getWillBeLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setWillBeLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 betradar_id = 11;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getBetradarId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setBetradarId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.clearBetradarId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.MatchUpdate.prototype.hasBetradarId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_stream = 12;
 * @return {boolean}
 */
proto.proto.LiveData.MatchUpdate.prototype.getHasStream = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setHasStream = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool has_scout = 13;
 * @return {boolean}
 */
proto.proto.LiveData.MatchUpdate.prototype.getHasScout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setHasScout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 priority = 14;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bytes match_info = 15;
 * @return {string}
 */
proto.proto.LiveData.MatchUpdate.prototype.getMatchInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes match_info = 15;
 * This is a type-conversion wrapper around `getMatchInfo()`
 * @return {string}
 */
proto.proto.LiveData.MatchUpdate.prototype.getMatchInfo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMatchInfo()));
};


/**
 * optional bytes match_info = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMatchInfo()`
 * @return {!Uint8Array}
 */
proto.proto.LiveData.MatchUpdate.prototype.getMatchInfo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMatchInfo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setMatchInfo = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.clearMatchInfo = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.MatchUpdate.prototype.hasMatchInfo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int32 hash = 16;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getHash = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setHash = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 last_update = 17;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setLastUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 games_count = 18;
 * @return {number}
 */
proto.proto.LiveData.MatchUpdate.prototype.getGamesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchUpdate} returns this
 */
proto.proto.LiveData.MatchUpdate.prototype.setGamesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.MatchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.MatchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData.MatchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.MatchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    result: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData.MatchResult}
 */
proto.proto.LiveData.MatchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData.MatchResult;
  return proto.proto.LiveData.MatchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData.MatchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData.MatchResult}
 */
proto.proto.LiveData.MatchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMatchId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.MatchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.MatchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData.MatchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.MatchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 match_id = 1;
 * @return {number}
 */
proto.proto.LiveData.MatchResult.prototype.getMatchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchResult} returns this
 */
proto.proto.LiveData.MatchResult.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 game_type = 2;
 * @return {number}
 */
proto.proto.LiveData.MatchResult.prototype.getGameType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchResult} returns this
 */
proto.proto.LiveData.MatchResult.prototype.setGameType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.proto.LiveData.MatchResult.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.MatchResult} returns this
 */
proto.proto.LiveData.MatchResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string result = 4;
 * @return {string}
 */
proto.proto.LiveData.MatchResult.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveData.MatchResult} returns this
 */
proto.proto.LiveData.MatchResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveData.Settlement.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveData.Settlement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveData.Settlement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.Settlement.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    oldStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveData.Settlement}
 */
proto.proto.LiveData.Settlement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveData.Settlement;
  return proto.proto.LiveData.Settlement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveData.Settlement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveData.Settlement}
 */
proto.proto.LiveData.Settlement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOddId(value);
      break;
    case 2:
      var value = /** @type {!proto.proto.SettlementStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.SettlementStatus} */ (reader.readEnum());
      msg.setOldStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveData.Settlement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveData.Settlement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveData.Settlement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveData.Settlement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.proto.SettlementStatus} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 odd_id = 1;
 * @return {number}
 */
proto.proto.LiveData.Settlement.prototype.getOddId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LiveData.Settlement} returns this
 */
proto.proto.LiveData.Settlement.prototype.setOddId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SettlementStatus status = 2;
 * @return {!proto.proto.SettlementStatus}
 */
proto.proto.LiveData.Settlement.prototype.getStatus = function() {
  return /** @type {!proto.proto.SettlementStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.proto.SettlementStatus} value
 * @return {!proto.proto.LiveData.Settlement} returns this
 */
proto.proto.LiveData.Settlement.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SettlementStatus old_status = 3;
 * @return {!proto.proto.SettlementStatus}
 */
proto.proto.LiveData.Settlement.prototype.getOldStatus = function() {
  return /** @type {!proto.proto.SettlementStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.SettlementStatus} value
 * @return {!proto.proto.LiveData.Settlement} returns this
 */
proto.proto.LiveData.Settlement.prototype.setOldStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.LiveData.Settlement} returns this
 */
proto.proto.LiveData.Settlement.prototype.clearOldStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.Settlement.prototype.hasOldStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MatchUpdate match = 1;
 * @return {?proto.proto.LiveData.MatchUpdate}
 */
proto.proto.LiveData.prototype.getMatch = function() {
  return /** @type{?proto.proto.LiveData.MatchUpdate} */ (
    jspb.Message.getWrapperField(this, proto.proto.LiveData.MatchUpdate, 1));
};


/**
 * @param {?proto.proto.LiveData.MatchUpdate|undefined} value
 * @return {!proto.proto.LiveData} returns this
*/
proto.proto.LiveData.prototype.setMatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.proto.LiveData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LiveData} returns this
 */
proto.proto.LiveData.prototype.clearMatch = function() {
  return this.setMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.prototype.hasMatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LiveOddsData odds = 2;
 * @return {?proto.proto.LiveData.LiveOddsData}
 */
proto.proto.LiveData.prototype.getOdds = function() {
  return /** @type{?proto.proto.LiveData.LiveOddsData} */ (
    jspb.Message.getWrapperField(this, proto.proto.LiveData.LiveOddsData, 2));
};


/**
 * @param {?proto.proto.LiveData.LiveOddsData|undefined} value
 * @return {!proto.proto.LiveData} returns this
*/
proto.proto.LiveData.prototype.setOdds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.proto.LiveData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LiveData} returns this
 */
proto.proto.LiveData.prototype.clearOdds = function() {
  return this.setOdds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.prototype.hasOdds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MatchResult match_result = 3;
 * @return {?proto.proto.LiveData.MatchResult}
 */
proto.proto.LiveData.prototype.getMatchResult = function() {
  return /** @type{?proto.proto.LiveData.MatchResult} */ (
    jspb.Message.getWrapperField(this, proto.proto.LiveData.MatchResult, 3));
};


/**
 * @param {?proto.proto.LiveData.MatchResult|undefined} value
 * @return {!proto.proto.LiveData} returns this
*/
proto.proto.LiveData.prototype.setMatchResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.proto.LiveData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LiveData} returns this
 */
proto.proto.LiveData.prototype.clearMatchResult = function() {
  return this.setMatchResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.prototype.hasMatchResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Settlement settlement = 4;
 * @return {?proto.proto.LiveData.Settlement}
 */
proto.proto.LiveData.prototype.getSettlement = function() {
  return /** @type{?proto.proto.LiveData.Settlement} */ (
    jspb.Message.getWrapperField(this, proto.proto.LiveData.Settlement, 4));
};


/**
 * @param {?proto.proto.LiveData.Settlement|undefined} value
 * @return {!proto.proto.LiveData} returns this
*/
proto.proto.LiveData.prototype.setSettlement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.proto.LiveData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LiveData} returns this
 */
proto.proto.LiveData.prototype.clearSettlement = function() {
  return this.setSettlement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveData.prototype.hasSettlement = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LiveScoreData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LiveScoreData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LiveScoreData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveScoreData.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.proto.ScoreData.toObject(includeInstance, f),
    entryId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LiveScoreData}
 */
proto.proto.LiveScoreData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LiveScoreData;
  return proto.proto.LiveScoreData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LiveScoreData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LiveScoreData}
 */
proto.proto.LiveScoreData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScoreData;
      reader.readMessage(value,proto.proto.ScoreData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LiveScoreData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LiveScoreData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LiveScoreData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LiveScoreData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ScoreData.serializeBinaryToWriter
    );
  }
  f = message.getEntryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ScoreData data = 1;
 * @return {?proto.proto.ScoreData}
 */
proto.proto.LiveScoreData.prototype.getData = function() {
  return /** @type{?proto.proto.ScoreData} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScoreData, 1));
};


/**
 * @param {?proto.proto.ScoreData|undefined} value
 * @return {!proto.proto.LiveScoreData} returns this
*/
proto.proto.LiveScoreData.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LiveScoreData} returns this
 */
proto.proto.LiveScoreData.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LiveScoreData.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string entry_id = 2;
 * @return {string}
 */
proto.proto.LiveScoreData.prototype.getEntryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LiveScoreData} returns this
 */
proto.proto.LiveScoreData.prototype.setEntryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScoreData.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScoreData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScoreData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScoreData.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: (f = msg.getScore()) && proto.proto.Score.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScoreData}
 */
proto.proto.ScoreData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScoreData;
  return proto.proto.ScoreData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScoreData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScoreData}
 */
proto.proto.ScoreData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = new proto.proto.Score;
      reader.readMessage(value,proto.proto.Score.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScoreData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScoreData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScoreData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScoreData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Score.serializeBinaryToWriter
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.proto.ScoreData.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScoreData} returns this
 */
proto.proto.ScoreData.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Score score = 2;
 * @return {?proto.proto.Score}
 */
proto.proto.ScoreData.prototype.getScore = function() {
  return /** @type{?proto.proto.Score} */ (
    jspb.Message.getWrapperField(this, proto.proto.Score, 2));
};


/**
 * @param {?proto.proto.Score|undefined} value
 * @return {!proto.proto.ScoreData} returns this
*/
proto.proto.ScoreData.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScoreData} returns this
 */
proto.proto.ScoreData.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScoreData.prototype.hasScore = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Score.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Score.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Score} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Score.toObject = function(includeInstance, msg) {
  var f, obj = {
    clock: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoreAwayPeriod1: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    scoreAwayPeriod1Tiebreak: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    scoreAwayPeriod2: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    scoreAwayTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    scoreHomePeriod1: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    scoreHomePeriod1Tiebreak: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    scoreHomePeriod2: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    scoreHomeTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Score}
 */
proto.proto.Score.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Score;
  return proto.proto.Score.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Score} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Score}
 */
proto.proto.Score.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClock(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreAwayPeriod1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreAwayPeriod1Tiebreak(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreAwayPeriod2(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreAwayTotal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreHomePeriod1(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreHomePeriod1Tiebreak(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreHomePeriod2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreHomeTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Score.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Score.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Score} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Score.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClock();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoreAwayPeriod1();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getScoreAwayPeriod1Tiebreak();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getScoreAwayPeriod2();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getScoreAwayTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getScoreHomePeriod1();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getScoreHomePeriod1Tiebreak();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getScoreHomePeriod2();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getScoreHomeTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string clock = 1;
 * @return {string}
 */
proto.proto.Score.prototype.getClock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setClock = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double score_away_period_1 = 2;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreAwayPeriod1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreAwayPeriod1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score_away_period_1_tiebreak = 3;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreAwayPeriod1Tiebreak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreAwayPeriod1Tiebreak = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double score_away_period_2 = 4;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreAwayPeriod2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreAwayPeriod2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double score_away_total = 5;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreAwayTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreAwayTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double score_home_period_1 = 6;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreHomePeriod1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreHomePeriod1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double score_home_period_1_tiebreak = 7;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreHomePeriod1Tiebreak = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreHomePeriod1Tiebreak = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double score_home_period_2 = 8;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreHomePeriod2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreHomePeriod2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double score_home_total = 9;
 * @return {number}
 */
proto.proto.Score.prototype.getScoreHomeTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Score} returns this
 */
proto.proto.Score.prototype.setScoreHomeTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.proto.EventStatus = {
  NOT_ACTIVE: 0,
  NOT_STARTED: 1,
  LIVE: 2
};

/**
 * @enum {number}
 */
proto.proto.SettlementStatus = {
  UNSETTLED: 0,
  WON: 1,
  HALF_WON: 2,
  REFUNDED: 3,
  HALF_REFUNDED: 4,
  LOST: 5
};

goog.object.extend(exports, proto.proto);
