import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectTickets } from "../../main/store/ticketSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { getRightSidebarOpen, selectMenuList, setRightSideBarOpen } from "../../main/store/uiSlice";
import { CasinoContainer } from "./CasinoContainer";
import { BetslipContainer } from "./BetslipContainer";
import { TrackerContainer } from "./TrackerContainer";
import { useLocation, useParams } from "react-router-dom";
import { useIsLive } from "src/app/hooks/useIsLive";

const RightSidebar = () => {
  const tickets = useSelector(selectTickets);
  const open = useSelector(getRightSidebarOpen);
  const menuList = useSelector(selectMenuList);
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLive } = useIsLive();
  const { id } = useParams();

  const setOpen = (visible) => {
    dispatch(setRightSideBarOpen(visible));
  };

  const toggleSidebar = () => {
    setOpen(!open);
  };


  return (
    <>
      <Box
        className="overflow-hidden transition-all duration-300"
        aria-label="RightSideBar"
        style={{ maxWidth: open ? "350px" : "0px", opacity: open ? 1 : 0, minWidth: open ? "350px" : "0px" }}
      >
        <Box
          className="relative min-w-fit h-fit origin-right ease-out max-h-[calc(var(--app-height)-134px)] overflow-y-auto pt-[12px]"
          sx={{ scrollbarWidth: "none" }}
        >
          <Box className="w-[350px] rounded-[8px] bg-transparent text-white h-[calc(var(--app-height))] bg-green-600">
            <Box className="flex flex-col gap-[12px] pb-[12px]">
              {location.pathname.startsWith("/sports/detail") && isLive && <TrackerContainer key={id} />}
              {(menuList.includes('sports_betting') || menuList.includes('live')) && (
                <BetslipContainer closeSidebar={() => setOpen(false)} />
              )}
              {(menuList.includes('casino') || menuList.includes('live_casino')) && (
                <CasinoContainer 
                  casinoTab={menuList.includes('casino')}
                  liveCasinoTab={menuList.includes('live_casino')}
                />
              )}
            </Box>
          </Box>
          <Box className="w-full min-h-[16px]"></Box>
        </Box>
      </Box>
      <button
        onClick={toggleSidebar}
        className="absolute -right-[3px] bottom-[80px] bg-[#134D38] text-white transition-all duration-100 px-[4px] py-[16px] rounded-l-[12px] z-[100] flex flex-col gap-[8px] border-[#ffffff] shadow-md border-l-[2px] border-y-[2px] items-center hover:bg-[green]"
        style={{
          opacity: open ? 0 : 1,
          visibility: open ? "hidden" : "visible",
        }}
      >
        <FontAwesomeIcon icon={faTicket} size="2x" transform={{ rotate: 90 }} />
        <span>{tickets.length}</span>
      </button>
    </>
  );
};

export default RightSidebar;
