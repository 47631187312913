import { Box, useTheme } from "@mui/material";
import { React, useEffect, useState, memo } from "react";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { getCMSList } from "../../store/cmsSlice";
import { useScreenMd } from "src/app/hooks/useScreens";

const carouselStyles = {
  img: {
    width: "100%",
    height: "233px",
    borderRadius: "6px",
    objectFit: "cover",
  },
  indicator: {
    position: "absolute",
    bottom: "10px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    zIndex: 1,
  },
};

const DashboardBanner = ({ category }) => {
  const dispatch = useDispatch();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [banners, setBanners] = useState([]);
  const theme = useTheme();
  const isMd = useScreenMd();

  useEffect(() => {
    dispatch(getCMSList("banners")).then((res) => {
      const list = res.payload;
      setBanners(
        [...list]
          .filter((item) => item.section === category && item.active && item.language === currentLanguageId && (isMd ? item.device === 1 : item.device === 2))
          .sort((a, b) => a.position < b.position)
      );
    });
  }, [currentLanguageId, category, isMd]);

  console.log('banner rendering')

  return (
    <Box
      aria-label="dashboardBanner"
      className="w-full max-w-full mx-auto rounded-[12px] overflow-hidden max-h-[230px]"
      sx={{
        ".slick-prev:before": {
          // visibility: "hidden",
        },
        ".slick-next:before": {
          // visibility: "hidden",
        },
      }}
    >
      {banners.length > 0 && banners[0].language === currentLanguageId && (
        <Carousel
          indicators={true}
          indicatorContainerProps={{ style: carouselStyles.indicator }}
        >
          {banners.map((banner) => {
            return (
              <img
                key={banner.id}
                src={banner.main_image}
                alt=""
                style={carouselStyles.img}
                className="carousel"
              />
            )
          })}
        </Carousel>
      )}
    </Box>
  );
}

export default memo(DashboardBanner);
