import { Box } from "@mui/material"
import { WhiteBoard } from "./WhiteBoard"
import { HorizontalScroll } from "app/shared-components/HorizontalScroll"
import { SliderCard } from "../../sports/components/SliderCard"
import React from 'react'
import { getBoostedOdds, selectBoostedOdds } from "../../store/boostedOdds"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { memo } from "react"
import i18next from "i18next"
import { selectCurrentLanguageId } from "app/store/i18nSlice"

export const BoostOddsContainer = memo(() => {
  const boostedOdds = useSelector(selectBoostedOdds);
  const dispatch = useDispatch()
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getBoostedOdds());

  }, [])

  return (
    <Box className="px-[4px]">
      <WhiteBoard title={i18next.t("Boosted_Odds")} moreButtonHidden>
        <Box className="max-w-full">
          <HorizontalScroll>
            <Box className="flex gap-[12px] justify-start">
              {[...boostedOdds]
                .sort((a, b) =>
                  a.sportEvent?.start_at < b.sportEvent?.start_at ? -1 : 1
                )
                .map((data, index) => (
                  <Box key={index} className="ml-[4px]">
                    <SliderCard event={data} />
                  </Box>
                ))}
            </Box>
          </HorizontalScroll>
        </Box>
      </WhiteBoard>
    </Box>
  )
})